import React, { useEffect, useState } from "react";
import { title } from "process";
import { Button, Modal } from "react-bootstrap";
import DataTable, { defaultThemes } from "react-data-table-component";
import { toast, ToastContainer } from "react-toastify";
import { ApiDelete, ApiGet, ApiPost, ApiPut } from "../../../helpers/API/ApiData";
import { Dialog, IconButton, List, Slide, Toolbar, Tooltip } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import { ThreeCircles } from "react-loader-spinner";

const Transition: any = React.forwardRef(function Transition(props: any, ref: any) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Socialmedia = () => {
  const [isLoaderVisible, setIsLoaderVisible] = useState<boolean>(false);
  const [isEditApi, setIsEditApi] = useState<boolean>(false);
  const [isDeleteApi, setIsDeleteApi] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isAdd, setIsAdd] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [countPerPage, setCountPerPage] = useState<number>(10);
  const [count, setCount] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [getsocialmedia, setGetsocialmedia] = useState<any>({});
  const [idForUpdate, setIdForUpdate] = useState<string>("");
  const [idForDelete, setIdForDelete] = useState<string>("");
  const [inputValue, setInputValue] = useState<any>({});
  const [filterData, setFilterData] = useState<any>();
  const [errors, setErrors] = useState<any>({});

  useEffect(() => {
    title === "Sayahi | Dashboard"
      ? (document.title = title)
      : (document.title = "Sayahi | Contact");
  }, []);

  useEffect(() => {
    getAllcontact();
  }, [page, countPerPage]);

  const handleOnChange = (e: any) => {
    const { name, value } = e.target;
    setInputValue({ ...inputValue, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const handleOnChangeImg = (e: any) => {
    const { name } = e.target;
    setInputValue({ ...inputValue, [name]: e.target.files[0] });
    setErrors({ ...errors, [name]: "" });
  };

  const handleClose = () => {
    setInputValue({});
    setErrors({});
    setIsAdd(false);
    // setIsEditApi(false);
    setIsDeleteApi(false)
  };
  const validateFormForAdd = () => {
    let formIsValid = true;
    let errors: any = {};

    if (inputValue && !inputValue.name?.trim()) {
      formIsValid = false;
      errors["name"] = "Bitte überprüfen Sie ihre Eingabe*";
    }

    if (inputValue && !inputValue.icon) {
      formIsValid = false;
      errors["icon"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (inputValue && !inputValue.link) {
      formIsValid = false;
      errors["link"] = "Bitte überprüfen Sie ihre Eingabe*";
    }

    setErrors(errors);
    return formIsValid;
  };

  const getAllcontact = async () => {
    setIsLoaderVisible(true);
    await ApiGet(`socialMedia/getSocialMedia`)
      .then((res: any) => {
       
        setIsLoaderVisible(false);
        setGetsocialmedia(res?.data?.payload?.socialMedia);
        // setFilterData(res?.data?.data);
        // setCount(res?.data?.data?.length);
        // toast.success(res?.data?.message)
      })
      .catch((err) => { });
  };
  const handleAdd = (e: any) => {
    e.preventDefault();
    if (validateFormForAdd()) {
      setLoading(true);
      let formData = new FormData();
      formData.append("name", inputValue?.name.trim());
      formData.append("icon", inputValue?.icon);
      formData.append("link", inputValue?.link);

      ApiPost(`socialMedia/addSocialMedia`, formData)
        .then((res: any) => {
          if (res?.status == 200) {
           
            setIsAdd(false);
            // toast.success(res?.data?.message);
            setInputValue({});
            getAllcontact();
            // getAll();

            setLoading(false);
          } else {
            // toast.error(res?.data?.message);
            setLoading(false);
          }
        })
        .catch((err) => {
          // toast.error(err.message);
          setLoading(false);
        });
    }
  };

  const deleteMediaData = async () => {
    setLoading(true);
    const deleteData: any = {
      isActive: false
    }
    await ApiPut(`socialMedia/updateSocialMedia/${idForDelete}`, deleteData)
      .then((res: any) => {
        if (res?.status == 200) {
          setIsDeleteApi(false);
          toast.success(res?.data?.message);
          getAllcontact();
          setLoading(false);
        } else {
          toast.error(res?.data?.message);
          setLoading(false);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        setLoading(false);
      });

  }


  const handleUpdate = (e: any) => {
    e.preventDefault();
    if (validateFormForAdd()) {
      setLoading(true);
      let formData = new FormData();
      formData.append("name", inputValue?.name);
      formData.append("link", inputValue?.link);
      formData.append("icon", inputValue?.icon);

      ApiPut(`socialMedia/updateSocialMedia/${idForUpdate}`, formData)
        .then((res: any) => {
          if (res?.status == 200) {
            setInputValue({});
            setIsAdd(false);
            setIsEditApi(false);
            toast.success(res?.data?.message);
            getAllcontact();
            setLoading(false);
          } else {
            toast.error(res?.data?.message);
            setLoading(false);
          }
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
          setLoading(false);
        });
    }
  };

  const columns: any = [
    {
      name: "Nr.",
      cell: (row: any, index: any) => (page - 1) * countPerPage + (index + 1),
      width: "10%",
    },
    {
      name: "Name",
      selector: (row: any) => row?.name,
      sortable: true,
      width: "25%",
    },
    {
      name: "Verknüpfung",
      selector: (row: any) => row?.link,
      sortable: true,
      width: "25%",
    },
    {
      name: "Symbol",
      cell: (row: any) => {
        return (
          <>
            <div className="mt-4 mb-4 p-1 border border-dark rounded">
              <img style={{backgroundColor:"black"}} src={row?.icon} width={80} height={50} />
            </div>
          </>
        );
      },
      sortable: true,
      width: "25%",
    },
    {
      name: "Aktionen",
      cell: (row: any) => {
        return (
          <>
            <div className="d-flex justify-content-between">

            <div
                className="cursor-pointer pl-2"
                onClick={() => {
                  setIsAdd(true);
                  setIsEditApi(true);
                  setInputValue({
                    icon: row?.icon,
                    link: row?.link,
                    name: row?.name
                  });
                  setIdForUpdate(row?._id);
                }}
              >
                <Tooltip title="Bearbeiten" arrow>
                  <CreateIcon />
                </Tooltip>
              </div>
              <div
                className="cursor-pointer pl-2"
                onClick={() => {

                  setIsDeleteApi(true);
                  setIdForDelete(row?._id);
                }}
              >
                <Tooltip title="Löschen" arrow>
                  <DeleteIcon />
                </Tooltip>
              </div>
            </div>
          </>
        );
      },
      width: "15%",
    },


  ];

  const handleSearch = (e: any) => {
    var value = e.target.value.toLowerCase();
    setGetsocialmedia(() =>
      filterData.filter((item: any) => item?.name?.toLowerCase()?.includes(value))
    );
  };

  // * Table Style
  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: defaultThemes.default.divider.default,
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };

  console.log("obj11ect", getsocialmedia)

  return (
    <>
      <div className="card p-1">
        {document.title === "Sayahi | Contact" && <ToastContainer />}
        <div className="p-2 mb-2">
          <div className="row mb-4 pr-3">
            <div className="col d-flex justify-content-between">
              <h2 className="pl-3 pt-2">Social Media</h2>

            </div>
            {/* <div className="col">
              <div>
                <input
                  type="text"
                  className={`form-control form-control-solid `}
                  name="search"
                  // value={search}
                  placeholder="social media"
                  onChange={(e) => handleSearch(e)}
                />
              </div>
            </div> */}
            <div className="cus-medium-button-style button-height">
              {getsocialmedia.length < 3? <button
                onClick={() => {
                  setIsAdd(true);
                }}
                className="btn btn-success mr-2"
              >
               Soziale Medien hinzufügen
              </button> : null
              }

            </div>
          </div>

          {/*close*/}
          <Modal show={isDeleteApi} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title className="text-danger">Alert!</Modal.Title>
            </Modal.Header>
            <Modal.Body>Sind Sie sicher, dass Sie diese sozialen Medien löschen möchten?</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
              Abbrechen
              </Button>
              <Button variant="danger" onClick={() => deleteMediaData()}>
              Löschen
              </Button>
            </Modal.Footer>
          </Modal>

          <DataTable
            columns={columns}
            data={getsocialmedia}
            customStyles={customStyles}
            style={{
              marginTop: "-3rem",
            }}
            progressPending={isLoaderVisible}
            progressComponent={<ThreeCircles color="#334D52" height={30} width={30} />}
            highlightOnHover
            // pagination
            //   // paginationServer
            //   // paginationTotalRows={count}
            //   paginationPerPage={countPerPage}
            //   paginationRowsPerPageOptions={[10, 20, 25, 50, 100]}
            //   paginationDefaultPage={page}
            //   onChangePage={(page) => {
            //     setPage(page);
            //   }}
            //   onChangeRowsPerPage={(rowPerPage) => {
            //     setCountPerPage(rowPerPage);
            //   }}
            pagination
            paginationServer
            // paginationTotalRows={count}
            paginationPerPage={countPerPage}
            paginationRowsPerPageOptions={[10, 20, 25, 50, 100]}
            paginationDefaultPage={page}
            onChangePage={(page) => {
              setPage(page);
            }}
            onChangeRowsPerPage={(rowPerPage) => {
              setCountPerPage(rowPerPage);
            }}
          />
        </div>
      </div>

      {isAdd ? (
        <Dialog fullScreen open={isAdd} onClose={handleClose} TransitionComponent={Transition}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
          <List>
            {isAdd === true && (
              <div className="form ml-30 ">
                {/* name */}
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">Name :</label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <input
                        type="text"
                        className={`form-control form-control-lg form-control-solid `}
                        id="name"
                        name="name"
                        value={inputValue.name}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["name"]}
                    </span>
                  </div>
                </div>
                {/* icon */}
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">Symbol :</label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <input
                        type="file"
                        className={`form-control form-control-lg form-control-solid `}
                        name="icon"
                        onChange={(e) => {
                          handleOnChangeImg(e);
                        }}
                        accept="icon/*"
                        required
                      />
                    </div>

                    <div>
                      {inputValue?.icon && (
                        <img
                          style={{
                            height: "60px",
                            width: "60px",
                            margin: "15px 0",
                            objectFit: "contain",
                            backgroundColor: "black"
                          }}
                          src={
                            inputValue?.icon?.toString()?.includes(".com") === true
                              ? inputValue?.icon
                              : URL.createObjectURL(inputValue?.icon)
                          }
                          alt=""
                        />
                      )}
                    </div>

                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["icon"]}
                    </span>
                  </div>
                </div>
                {/* link */}
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">Verknüpfung :</label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <input
                        type="text"
                        className={`form-control form-control-lg form-control-solid `}
                        id="link"
                        name="link"
                        value={inputValue.link}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["link"]}
                    </span>
                  </div>
                </div>

                {/* button od add and edit */}
                <div className="d-flex align-items-center justify-content-center">
                  {loading ? (
                    <button className="btn btn-success mr-2">
                      <span>{isEditApi ? "Speichern" : "Speichern"}</span>
                      {loading && <span className="mx-3 spinner spinner-white"></span>}
                    </button>
                  ) : (
                    <button
                      onClick={(e) => {
                        isEditApi ? handleUpdate(e) : handleAdd(e);
                      }}
                      className="btn btn-success mr-2"
                    >
                      <span>{isEditApi ? "Speichern" : "Speichern"}</span>
                      {loading && <span className="mx-3 spinner spinner-white"></span>}
                    </button>
                  )}
                </div>
                
              </div>
            )}
          </List>
        </Dialog>
      ) : null}
    </>
  );
};

export default Socialmedia;


