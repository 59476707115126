import React, { useEffect, useState } from "react";
import { title } from "process";
import { Button, Modal } from "react-bootstrap";
import DataTable, { defaultThemes } from "react-data-table-component";
import { toast, ToastContainer } from "react-toastify";
import { ApiDelete, ApiGet, ApiPost, ApiPut } from "../../../helpers/API/ApiData";
import { Dialog, IconButton, List, Slide, Toolbar, Tooltip } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import { ThreeCircles } from "react-loader-spinner";
import InfoIcon from "@material-ui/icons/Info";

const Transition: any = React.forwardRef(function Transition(props: any, ref: any) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Contact = () => {
  const [isLoaderVisible, setIsLoaderVisible] = useState<boolean>(false);
  const [isEditApi, setIsEditApi] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isAdd, setIsAdd] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [countPerPage, setCountPerPage] = useState<number>(10);
  const [count, setCount] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [getcontact, setGetcontact] = useState<any>([]);
  const [idForUpdate, setIdForUpdate] = useState<string>("");
  const [idForDelete, setIdForDelete] = useState<string>("");
  const [inputValue, setInputValue] = useState<any>({});
  const [filterData, setFilterData] = useState<any>();
  const [errors, setErrors] = useState<any>({});
  const [idFordeleteaboutapp, setIdFordeleteaboutapp] = useState<any>({});
  const [isinfo, setIsinfo] = useState<boolean>(false);
  const [serachbox, setSerachbox] = useState<any>("");


  useEffect(() => {
    title === "Sayahi | Dashboard"
      ? (document.title = title)
      : (document.title = "Sayahi | Contact");
  }, []);

  useEffect(() => {
    getAllcontact();
  }, [page, countPerPage]);

  const handleCloseDelete = () => {
    setShow(false);
  };
  const handleClose = () => {
    setInputValue({});
    setErrors({});
    setIsAdd(false);
    setIsEditApi(false);
    setIsinfo(false);
  };
  const handleOnChange = (e: any) => {
    const { name, value } = e.target;
    setInputValue({ ...inputValue, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };
  const validateFormUpdate = () => {
    let formIsValid = true;
    let errors: any = {};
    if (inputValue && !inputValue.firstName) {
      formIsValid = false;
      errors["firstName"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (inputValue && !inputValue.surName) {
      formIsValid = false;
      errors["surName"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (inputValue && !inputValue.email) {
      formIsValid = false;
      errors["email"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (inputValue && !inputValue.herr) {
      formIsValid = false;
      errors["herr"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (inputValue && !inputValue.message) {
      formIsValid = false;
      errors["message"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (inputValue && !inputValue.callbackNumber) {
      formIsValid = false;
      errors["callbackNumber"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    setErrors(errors);
    return formIsValid;
  };
  const getAllcontact = async () => {
    setIsLoaderVisible(true);
    await ApiGet(`contact/getContact`)
      .then((res: any) => {
        console.log("res", res);
        setIsLoaderVisible(false);
        setGetcontact(res?.data?.payload?.contact);
        // setFilterData(res?.data?.data);
        // setCount(res?.data?.data?.length);
        // toast.success(res?.data?.message)
      })
      .catch((err) => { });
  };
  const handleUpdate = (e: any) => {
    e.preventDefault();
    if (validateFormUpdate()) {
      setLoading(true);
      let formData = {
        firstName: inputValue?.firstName,
        surName: inputValue?.surName,
        email: inputValue?.email,
        message: inputValue?.message,
        herr: inputValue?.herr,
        callbackNumber: inputValue?.callbackNumber,
      };

      ApiPut(`contact/updateContact/${idForUpdate}`, formData)
        .then((res: any) => {
          if (res?.status == 200) {
            setInputValue({});
            setIsAdd(false);
            setIsEditApi(false);
            toast.success(res?.data?.message);
            getAllcontact();
            setLoading(false);
          } else {
            toast.error(res?.data?.message);
            setLoading(false);
          }
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
          setLoading(false);
        });
    }
  };

  const handleDelete = () => {
    setLoading(true);
    let data = {
      isActive: "false",
    };
    ApiPut(`contact/updateContact/${idFordeleteaboutapp?._id}`, data)
      .then((res: any) => {
        console.log("res", res);
        if (res?.status == 200) {
          setShow(false);
          toast.success("Deleted Successfully");
          getAllcontact();
          setPage(1);
          setCount(0);
          setCountPerPage(countPerPage);
        } else {
          toast.error(res?.data?.message);
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }

  const columns: any = [
    {
      name: "Nr.",
      cell: (row: any, index: any) => (page - 1) * countPerPage + (index + 1),
      width: "5%",
    },
    {
      name: "Vorname",
      selector: (row: any) => row?.firstName,
      sortable: true,
      width: "25%",
    },
    {
      name: "Nachname",
      selector: (row: any) => row?.surName,
      sortable: true,
      width: "25%",
    },
    {
      name: "E-Mail",
      selector: (row: any) => row?.email,
      sortable: true,
      width: "30%",
    },
    {
      name: "Aktionen",
      cell: (row: any) => {
        return (
          <>
            <div className="d-flex justify-content-between">
              {/* <div
                className="cursor-pointer pl-2"
                onClick={() => {
                  setIsAdd(true);
                  setInputValue({
                    firstName: row?.firstName,
                    surName: row?.surName,
                    email: row?.email,
                    message: row?.message,
                    herr: row?.herr,
                    callbackNumber:row?.callbackNumber,
                  });
                  setIdForUpdate(row?._id);
                }}
              >
                <Tooltip title="Edit" arrow>
                  <CreateIcon />
                </Tooltip>
              </div> */}
              <div>
                <div
                  className="cursor-pointer pl-2"
                  onClick={() => {
                    setIsinfo(true);
                    setInputValue({
                      firstName: row?.firstName,
                      surName: row?.surName,
                      email: row?.email,
                      message: row?.message,
                      herr: row?.herr,
                      callbackNumber: row?.callbackNumber,
                    });
                  }}
                >
                  <Tooltip title="Information" arrow>
                    <InfoIcon />
                  </Tooltip>
                </div>
              </div>
              <div
                className="cursor-pointer"
                onClick={() => {
                  setShow(true);
                  setIdFordeleteaboutapp(row);
                }}
              >
                <Tooltip title="Löschen" arrow>
                  <DeleteIcon />
                </Tooltip>
              </div>
            </div>
          </>
        );
      },
    },
  ];

  // * Table Style
  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: defaultThemes.default.divider.default,
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };



  const SearchfilterData = getcontact && getcontact?.filter((el: any, index: any) => {
    if (serachbox === "") {
      return el;
    } else if (serachbox !== "") {
      let fullName = `${el.firstName} ${el.surName}`;
      let res = fullName.toLowerCase();
      return res.match(serachbox.toLowerCase());
    }
  });

  const handleSearch = (e: any) => {
    var value = e.target.value;
    setSerachbox(value)
  };

  return (
    <>
      <div className="card p-1">
        {document.title === "Sayahi | Contact" && <ToastContainer />}
        <div className="p-2 mb-2">
          <div className="row mb-4 pr-3">
            <div className="col d-flex justify-content-between">
              <h2 className="pl-3 pt-2">Kontakt</h2>
            </div>
            <div className="col">
              <div>
                <input
                  type="text"
                  className={`form-control form-control-solid `}
                  name="search"
                  // value={search}
                  placeholder="Kontakt suchen…"
                  onChange={(e) => handleSearch(e)}
                />
              </div>
            </div>
            {/* <div className="cus-medium-button-style button-height">
              <button
                onClick={() => {
                  setIsAdd(true);
                }}
                className="btn btn-success mr-2"
              >
                Add Shop
              </button>
            </div> */}
          </div>

          {/* delete model */}
          <Modal show={show} onHide={handleCloseDelete}>
            <Modal.Header closeButton>
              <Modal.Title className="text-danger">Alert!</Modal.Title>
            </Modal.Header>
            <Modal.Body>Sind Sie sicher, dass Sie diesen Kontakt löschen möchten?</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseDelete}>
                Abbrechen
              </Button>
              <Button
                variant="danger"
                onClick={() => {
                  handleDelete();
                }}
              >
                Löschen
              </Button>
            </Modal.Footer>
          </Modal>
          {/* end delete model */}

          <DataTable
            columns={columns}
            data={SearchfilterData}
            customStyles={customStyles}
            style={{
              marginTop: "-3rem",
            }}
            progressPending={isLoaderVisible}
            progressComponent={<ThreeCircles color="#334D52" height={30} width={30} />}
            highlightOnHover
            pagination
              // paginationServer
              // paginationTotalRows={count}
              paginationPerPage={countPerPage}
              paginationRowsPerPageOptions={[10, 20, 25, 50, 100]}
              paginationDefaultPage={page}
              onChangePage={(page) => {
                setPage(page);
              }}
              onChangeRowsPerPage={(rowPerPage) => {
                setCountPerPage(rowPerPage);
              }}
            // pagination
            // paginationServer
            // paginationTotalRows={count}
            // paginationPerPage={countPerPage}
            // paginationRowsPerPageOptions={[10, 20, 25, 50, 100]}
            // paginationDefaultPage={page}
            // onChangePage={(page) => {
            //   setPage(page);
            // }}
            // onChangeRowsPerPage={(rowPerPage) => {
            //   setCountPerPage(rowPerPage);
            // }}
          />
        </div>
      </div>

      {isAdd ? (
        <Dialog fullScreen open={isAdd} onClose={handleClose} TransitionComponent={Transition}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
          <List>
            {isAdd === true && (
              <div className="form ml-30 ">

                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">Vorname</label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <input
                        type="text"
                        className={`form-control form-control-lg form-control-solid `}
                        id="firstName"
                        name="firstName"
                        value={inputValue?.firstName}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["firstName"]}
                    </span>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">Nachname</label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <input
                        type="text"
                        className={`form-control form-control-lg form-control-solid `}
                        id="surName"
                        name="surName"
                        value={inputValue?.surName}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["surName"]}
                    </span>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">email</label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <input
                        type="email"
                        className={`form-control form-control-lg form-control-solid `}
                        id="email"
                        name="email"
                        value={inputValue.email}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["email"]}
                    </span>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">Botschaft</label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <input
                        type="text"
                        className={`form-control form-control-lg form-control-solid `}
                        id="message"
                        name="message"
                        value={inputValue.message}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["message"]}
                    </span>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">Herrin</label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <input
                        type="text"
                        className={`form-control form-control-lg form-control-solid `}
                        id="herr"
                        name="herr"
                        value={inputValue.herr}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["herr"]}
                    </span>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">Rückrufnummer</label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <input
                        type="text"
                        className={`form-control form-control-lg form-control-solid `}
                        id="callbackNumber"
                        name="callbackNumber"
                        value={inputValue.callbackNumber}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["callbackNumber"]}
                    </span>
                  </div>
                </div>

                <div className="d-flex align-items-center justify-content-center">
                  {loading ? (
                    <button className="btn btn-success mr-2">
                      {loading && <span className="mx-3 spinner spinner-white"></span>}
                    </button>
                  ) : (
                    <button
                      onClick={(e) => {
                        handleUpdate(e);
                      }}
                      className="btn btn-success mr-2"
                    >
                      <span>bearbeiten</span>
                      {loading && <span className="mx-3 spinner spinner-white"></span>}
                    </button>
                  )}
                </div>
              </div>
            )}
          </List>
        </Dialog>
      ) : null}

      {isinfo ? (
        <Dialog fullScreen open={isinfo} onClose={handleClose} TransitionComponent={Transition}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
          <List>

            {isinfo && (
              <>

                <div className="ml-40">
                  <div className="form cus-container" >

                    {/* fName */}
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">
                        Vorname :
                      </label>
                      <div className="col-lg-9 col-xl-6 pt-3">
                        <div>
                          <span>{inputValue.firstName}</span>
                        </div>
                      </div>
                    </div>

                    {/* surname */}
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">
                      Nachname :
                      </label>
                      <div className="col-xl-3 col-lg-3 pt-3">
                        <div>
                          <span>{inputValue?.surName}</span>
                        </div>
                      </div>
                    </div>

                    {/* Email */}
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">
                      E-Mail :
                      </label>
                      <div className="col-xl-3 col-lg-3 pt-3">
                        <div>
                          <span>{inputValue?.email}</span>
                        </div>
                      </div>
                    </div>

                    {/* Message */}
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">
                        Nachricht :
                      </label>
                      <div className="col-xl-3 col-lg-3 pt-3">
                        <div>
                          <span>{inputValue?.message}</span>
                        </div>
                      </div>
                    </div>

                    {/* Herr */}
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">
                        Herrin :
                      </label>
                      <div className="col-xl-3 col-lg-3 pt-3">
                        <div>
                          <span>{inputValue?.herr}</span>
                        </div>
                      </div>
                    </div>

                    {/* Callback Number */}
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">
                        Rückrufnummer :
                      </label>
                      <div className="col-xl-3 col-lg-3 pt-3">
                        <div>
                          <span>{inputValue?.callbackNumber}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </List>
        </Dialog>
      ) : null}
    </>
  );
};

export default Contact;


