import React, { useEffect, useState } from "react";
import { title } from "process";
import { Button, Modal } from "react-bootstrap";
import DataTable, { defaultThemes } from "react-data-table-component";
import { toast, ToastContainer } from "react-toastify";
import {
  ApiDelete,
  ApiGet,
  ApiPost,
  ApiPut,
} from "../../../helpers/API/ApiData";
import {
  Dialog,
  IconButton,
  List,
  Slide,
  Toolbar,
  Tooltip,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import { ThreeCircles } from "react-loader-spinner";
import InfoIcon from "@material-ui/icons/Info";

const Transition: any = React.forwardRef(function Transition(
  props: any,
  ref: any
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Reportdamage = () => {
  const [isLoaderVisible, setIsLoaderVisible] = useState<boolean>(false);
  const [isEditApi, setIsEditApi] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isAdd, setIsAdd] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [countPerPage, setCountPerPage] = useState<number>(10);
  const [count, setCount] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [getReportDamage, setGetreportdamage] = useState<any>([]);
  const [idForUpdate, setIdForUpdate] = useState<string>("");
  const [idForDelete, setIdForDelete] = useState<string>("");
  const [inputValue, setInputValue] = useState<any>({});
  const [filterData, setFilterData] = useState<any>();
  const [errors, setErrors] = useState<any>({});
  const [idForinfo, setIdForinfo] = useState<any>({});
  const [isinfo, setIsinfo] = useState<any>(false);
  const [serachbox, setSerachbox] = useState<any>("");


  useEffect(() => {
    title === "Sayahi | Dashboard"
      ? (document.title = title)
      : (document.title = "Sayahi | Report Damage");
  }, []);

  useEffect(() => {
    getAllreportdamage();
  }, [page, countPerPage]);

  const handleClose = () => {
    setInputValue({});
    setErrors({});
    setIsAdd(false);
    setIsEditApi(false);
    setIsinfo(false);
  };
  const handleOnChange = (e: any) => {
    const { name, value } = e.target;
    setInputValue({ ...inputValue, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };
  const handleCloseDelete = () => {
    setShow(false);
  };
  const validateFormUpdate = () => {
    let formIsValid = true;
    let errors: any = {};
    if (inputValue && !inputValue.herr) {
      formIsValid = false;
      errors["herr"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (inputValue && !inputValue.firstName) {
      formIsValid = false;
      errors["firstName"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (inputValue && !inputValue.surName) {
      formIsValid = false;
      errors["surName"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (inputValue && !inputValue.vehicleManufacturer) {
      formIsValid = false;
      errors["vehicleManufacturer"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (inputValue && !inputValue.model) {
      formIsValid = false;
      errors["model"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (inputValue && !inputValue.firstRegistration) {
      formIsValid = false;
      errors["firstRegistration"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (inputValue && !inputValue.kmStand) {
      formIsValid = false;
      errors["kmStand"] = "Bitte überprüfen Sie ihre Eingabe*";
    }

    if (inputValue && !inputValue.dateofBirth) {
      formIsValid = false;
      errors["dateofBirth"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (inputValue && !inputValue.street) {
      formIsValid = false;
      errors["street"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (inputValue && !inputValue.plz) {
      formIsValid = false;
      errors["plz"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (inputValue && !inputValue.ort) {
      formIsValid = false;
      errors["ort"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (inputValue && !inputValue.telCar) {
      formIsValid = false;
      errors["telCar"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (inputValue && !inputValue.tel) {
      formIsValid = false;
      errors["tel"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (inputValue && !inputValue.email) {
      formIsValid = false;
      errors["email"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    setErrors(errors);
    return formIsValid;
  };
  const getAllreportdamage = async () => {
    setIsLoaderVisible(true);
    await ApiGet(`requestEvbNumber/getRequestEvbNumber`)
      .then((res: any) => {
      

        setIsLoaderVisible(false);
        setGetreportdamage(res?.data?.payload?.requestEvbNumber);
        // setFilterData(res?.data?.data);
        // setCount(res?.data?.data?.length);
        // toast.success(res?.data?.message)
      })
      .catch((err) => { });
  };
  // const handleUpdate = (e: any) => {
  //   e.preventDefault();
  //   if (validateFormUpdate()) {
  //     setLoading(true);
  //     ApiPut(`requestEvbNumber/updateRequestEvbNumber/${idForUpdate}`, inputValue)
  //       .then((res: any) => {
  //         if (res?.status == 200) {
  //           setInputValue({});
  //           setIsAdd(false);
  //           setIsEditApi(false);
  //           toast.success(res?.data?.message);
  //           getAllreportdamage();
  //           setLoading(false);
  //         } else {
  //           toast.error(res?.data?.message);
  //           setLoading(false);
  //         }
  //       })
  //       .catch((err) => {
  //         toast.error(err?.response?.data?.message);
  //         setLoading(false);
  //       });
  //   }
  // };
  
  const handleDelete = () => {
    // e.preventDefault();
    setLoading(true);
    let data = {
      isActive: false,
    };
    // formData.append("isActive", false);
    ApiPut(`requestEvbNumber/updateRequestEvbNumber/${idForDelete}`, data)
      .then((res: any) => {
        if (res?.status == 200) {
          setShow(false);
          toast.success("Deleted Successfully");
          getAllreportdamage();
          setPage(1);
          setCount(0);
          setCountPerPage(countPerPage);
        } else {
          toast.error(res?.data?.message);
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };
  const columns: any = [
    {
      name: "Nr.",
      cell: (row: any, index: any) => (page - 1) * countPerPage + (index + 1),
      width: "65px",
    },
    {
      name: "Vorname",
      selector: (row: any) => row?.firstName,
      sortable: true,
    },
    {
      name: "Nachname",
      selector: (row: any) => row?.surName,
      sortable: true,
    },
    {
      name: "E-Mail",
      selector: (row: any) => row?.email,
      sortable: true,
    },
    // {
    //   name: "zipCode",
    //   selector: (row: any) => row?.zipCode,
    //   sortable: true,
    // },
    // {
    //   name: "strasse",
    //   selector: (row: any) => row?.strasse,
    //   sortable: true,
    // },
    // {
    //   name: "ort",
    //   selector: (row: any) => row?.ort,
    //   sortable: true,
    // },
    // {
    //   name: "insuranceNumber",
    //   selector: (row: any) => row?.insuranceNumber,
    //   sortable: true,
    // },
    // {
    //   name: "herr",
    //   selector: (row: any) => row?.herr,
    //   sortable: true,
    // },
    // {
    //   name: "fax",
    //   selector: (row: any) => row?.fax,
    //   sortable: true,
    // },
    // {
    //   name: "object",
    //   selector: (row: any) => row?.fax,
    //   sortable: true,
    // },
    // {
    //   name: "description",
    //   selector: (row: any) => row?.description,
    //   sortable: true,
    // },
    // {
    //   name: "damageArt",
    //   selector: (row: any) => row?.damageArt,
    //   sortable: true,
    // },
    {
      name: "Aktionen",
      cell: (row: any) => {
        console.log("row", row);

        return (
          <>
            <div className="d-flex justify-content-between">
              {/* <div
                className="cursor-pointer pl-2"
                onClick={() => {
                  setIsAdd(true);
                  setIsEditApi(true);
                  setInputValue({
                    herr: row?.herr,
                    firstName: row?.firstName,
                    surName: row?.surName,
                    vehicleManufacturer: row?.vehicleManufacturer,
                    model: row?.model,
                    firstRegistration: row?.firstRegistration,
                    kmStand: row?.kmStand,
                    dateofBirth: row?.dateofBirth,
                    street: row?.street,
                    plz: row?.plz,
                    ort: row?.ort,
                    telCar: row?.telCar,
                    tel: row?.tel,
                    email: row?.email,
                  });
                  setIdForUpdate(row?._id);
                }}
              >
                <Tooltip title="Edit" arrow>
                  <CreateIcon />
                </Tooltip>
              </div> */}
              <div>
                <div
                  className="cursor-pointer pl-2"
                  onClick={() => {
                    setIsinfo(true);
                    setInputValue({
                      herr: row?.herr,
                      firstName: row?.firstName,
                      surName: row?.surName,
                      vehicleManufacturer: row?.vehicleManufacturer,
                      model: row?.model,
                      firstRegistration: row?.firstRegistration,
                      kmStand: row?.kmStand,
                      dateofBirth: row?.dateofBirth,
                      street: row?.street,
                      plz: row?.plz,
                      ort: row?.ort,
                      telCar: row?.telCar,
                      tel: row?.tel,
                      email: row?.email,
                    });
                  }}
                >
                  <Tooltip title="Information" arrow>
                    <InfoIcon />
                  </Tooltip>
                </div>
              </div>
              <div
                className="cursor-pointer"
                onClick={() => {
                  setShow(true);
                  setIdForDelete(row?._id);
                }}
              >
                <Tooltip title="Löschen" arrow>
                  <DeleteIcon />
                </Tooltip>
              </div>
            </div>
            <div
              className="cursor-pointer"
              onClick={() => {
                setShow(true);
                // setIdForDelete(row?._id);
              }}
            ></div>
          </>
        );
      },
    },
  ];

  // * Table Style
  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: defaultThemes.default.divider.default,
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };

  const SearchfilterData = getReportDamage && getReportDamage?.filter((el :any, index :any) => {
    if (serachbox === "") {
        return el;
    } else if (serachbox !== "") {
        let fullName = `${el.firstName} ${el.surName}`;
        let res = fullName.toLowerCase();
        return res.match(serachbox.toLowerCase());
    }
});

const handleSearch = (e: any) => {
  var value = e.target.value;
  setSerachbox(value)
};

  return (
    <>
      <div className="card p-1">
        {document.title === "Sayahi | Report Damage" && <ToastContainer />}
        <div className="p-2 mb-2">
          <div className="row mb-4 pr-3">
            <div className="col d-flex justify-content-between">
              <h2 className="pl-3 pt-2">EVB - Nummer anfordern</h2>
            </div>
            <div className="col">
              <div>
                <input
                  type="text"
                  className={`form-control form-control-solid `}
                  name="search"
                  // value={search}
                  placeholder="EVB - Nummer anfordern suchen…"
                  onChange={(e) => handleSearch(e)}
                />
              </div>
            </div>
            {/* <div className="cus-medium-button-style button-height">
              <button
                onClick={() => {
                  setIsAdd(true);
                }}
                className="btn btn-success mr-2"
              >
                Add Shop
              </button>
            </div> */}
          </div>

          {/* delete model */}
          <Modal show={show} onHide={handleCloseDelete}>
            <Modal.Header closeButton>
              <Modal.Title className="text-danger">Alert!</Modal.Title>
            </Modal.Header>
            <Modal.Body>Sind Sie sicher, dass Sie diese Anfrage EVB -Nummer löschen möchten?</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseDelete}>
               Abbrechen
              </Button>
              <Button
                variant="danger"
                onClick={() => {
                  handleDelete();
                }}
              >
                Löschen
              </Button>
            </Modal.Footer>
          </Modal>
          {/* end delete model */}

          <DataTable
            columns={columns}
            data={SearchfilterData}
            customStyles={customStyles}
            style={{
              marginTop: "-3rem",
            }}
            progressPending={isLoaderVisible}
            progressComponent={
              <ThreeCircles color="#334D52" height={30} width={30} />
            }
            highlightOnHover
            pagination
              // paginationServer
              // paginationTotalRows={count}
              paginationPerPage={countPerPage}
              paginationRowsPerPageOptions={[10, 20, 25, 50, 100]}
              paginationDefaultPage={page}
              onChangePage={(page) => {
                setPage(page);
              }}
              onChangeRowsPerPage={(rowPerPage) => {
                setCountPerPage(rowPerPage);
              }}
            // pagination
            // paginationServer
            // paginationTotalRows={count}
            // paginationPerPage={countPerPage}
            // paginationRowsPerPageOptions={[10, 20, 25, 50, 100]}
            // paginationDefaultPage={page}
            // onChangePage={(page) => {
            //   setPage(page);
            // }}
            // onChangeRowsPerPage={(rowPerPage) => {
            //   setCountPerPage(rowPerPage);
            // }}
          />
        </div>
      </div>


      {/* {isAdd ? (
        <Dialog
          fullScreen
          open={isAdd}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
          <List>
            {isAdd === true && (
              <div className="form ml-30 ">
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">
                  Herr
                  </label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <input
                        type="text"
                        className={`form-control form-control-lg form-control-solid `}
                        id="herr"
                        name="herr"
                        value={inputValue?.herr}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["herr"]}
                    </span>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">
                    firstName
                  </label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <input
                        type="text"
                        className={`form-control form-control-lg form-control-solid `}
                        id="firstName"
                        name="firstName"
                        value={inputValue?.firstName}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["firstName"]}
                    </span>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">
                    surName
                  </label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <input
                        type="text"
                        className={`form-control form-control-lg form-control-solid `}
                        id="surName"
                        name="surName"
                        value={inputValue?.surName}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["surName"]}
                    </span>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">
                  VehicleManufacturer
                  </label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <input
                        type="text"
                        className={`form-control form-control-lg form-control-solid `}
                        id="vehicleManufacturer"
                        name="vehicleManufacturer"
                        value={inputValue.vehicleManufacturer}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["vehicleManufacturer"]}
                    </span>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">
                  Model
                  </label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <input
                        type="text"
                        className={`form-control form-control-lg form-control-solid `}
                        id="model"
                        name="model"
                        value={inputValue.model}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["model"]}
                    </span>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">
                    FirstRegistration
                  </label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <input
                        type="text"
                        className={`form-control form-control-lg form-control-solid `}
                        id="firstRegistration"
                        name="firstRegistration"
                        value={inputValue.firstRegistration}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["firstRegistration"]}
                    </span>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">
                    KmStand
                  </label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <input
                        type="text"
                        className={`form-control form-control-lg form-control-solid `}
                        id="kmStand"
                        name="kmStand"
                        value={inputValue.kmStand}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["kmStand"]}
                    </span>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">
                  DateofBirth
                  </label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <input
                        type="date"
                        className={`form-control form-control-lg form-control-solid `}
                        id="dateofBirth"
                        name="dateofBirth"
                        value={inputValue?.dateofBirth}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["dateofBirth"]}
                    </span>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">
                    Street
                  </label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <input
                        type="text"
                        className={`form-control form-control-lg form-control-solid `}
                        id="street"
                        name="street"
                        value={inputValue.street}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["street"]}
                    </span>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">
                    Plz
                  </label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <input
                        type="text"
                        className={`form-control form-control-lg form-control-solid `}
                        id="plz"
                        name="plz"
                        value={inputValue.plz}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["plz"]}
                    </span>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">
                    Ort
                  </label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <input
                        type="text"
                        className={`form-control form-control-lg form-control-solid `}
                        id="ort"
                        name="ort"
                        value={inputValue.ort}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["ort"]}
                    </span>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">
                    TelCar
                  </label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <input
                        type="text"
                        className={`form-control form-control-lg form-control-solid `}
                        id="telCar"
                        name="telCar"
                        value={inputValue.telCar}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["telCar"]}
                    </span>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">
                    Tel
                  </label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <input
                        type="text"
                        className={`form-control form-control-lg form-control-solid `}
                        id="tel"
                        name="tel"
                        value={inputValue.tel}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["tel"]}
                    </span>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">
                  Email
                  </label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <input
                        type="text"
                        className={`form-control form-control-lg form-control-solid `}
                        id="email"
                        name="email"
                        value={inputValue.email}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["email"]}
                    </span>
                  </div>
                </div>

                <div className="d-flex align-items-center justify-content-center">
                  {loading ? (
                    <button className="btn btn-success mr-2">
                      <span>
                        {isEditApi ? "Edit" : "Add"}
                        <li></li>
                      </span>
                      {loading && (
                        <span className="mx-3 spinner spinner-white"></span>
                      )}
                    </button>
                  ) : (
                    <button
                      onClick={(e) => {
                        handleUpdate(e);
                      }}
                      className="btn btn-success mr-2"
                    >
                      <span>edit</span>
                      {loading && (
                        <span className="mx-3 spinner spinner-white"></span>
                      )}
                    </button>
                  )}
                </div>
              </div>
            )}
          </List>
        </Dialog>
      ) : null} */}


      {isinfo ? (
        <Dialog fullScreen open={isinfo} onClose={handleClose} TransitionComponent={Transition}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
          <List>

            {isinfo && (
              <>

                <div className="ml-40">
                  <div className="form cus-container" >

                    {/* fName */}
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">
                        Vorname :
                      </label>
                      <div className="col-lg-9 col-xl-6 pt-3">
                        <div>
                          <span>{inputValue.firstName}</span>
                        </div>
                      </div>
                    </div>

                    {/* surname */}
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">
                      Nachname :
                      </label>
                      <div className="col-xl-3 col-lg-3 pt-3">
                        <div>
                          <span>{inputValue?.surName}</span>
                        </div>
                      </div>
                    </div>

                    {/* Email */}
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">
                      E-Mail :
                      </label>
                      <div className="col-xl-3 col-lg-3 pt-3">
                        <div>
                          <span>{inputValue?.email}</span>
                        </div>
                      </div>
                    </div>

                    {/* ort */}
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">
                        Ort :
                      </label>
                      <div className="col-xl-3 col-lg-3 pt-3">
                        <div>
                          <span>{inputValue?.ort}</span>
                        </div>
                      </div>
                    </div>

                    {/* PLZ */}
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">
                        PLZ :
                      </label>
                      <div className="col-xl-3 col-lg-3 pt-3">
                        <div>
                          <span>{inputValue?.plz}</span>
                        </div>
                      </div>
                    </div>

                    {/* telephone */}
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">
                        Telefon :
                      </label>
                      <div className="col-xl-3 col-lg-3 pt-3">
                        <div>
                          <span>{inputValue?.tel}</span>
                        </div>
                      </div>
                    </div>

                    {/* herr */}
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">
                      Geschlecht :
                      </label>
                      <div className="col-xl-3 col-lg-3 pt-3">
                        <div>
                          <span>{inputValue?.herr}</span>
                        </div>
                      </div>
                    </div>

                    {/* vehicleManufacturer */}
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">
                        Fahrzeughersteller :
                      </label>
                      <div className="col-xl-3 col-lg-3 pt-3">
                        <div>
                          <span>{inputValue?.vehicleManufacturer}</span>
                        </div>
                      </div>
                    </div>

                    {/* model */}
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">
                        Modell :
                      </label>
                      <div className="col-xl-3 col-lg-3 pt-3">
                        <div>
                          <span>{inputValue?.model}</span>
                        </div>
                      </div>
                    </div>

                    {/* firstRegistration */}
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">
                      Erstzulassung :
                      </label>
                      <div className="col-xl-3 col-lg-3 pt-3">
                        <div>
                          <span>{inputValue?.firstRegistration}</span>
                        </div>
                      </div>
                    </div>

                    {/* kmStand */}
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">
                      Kilometerstand :
                      </label>
                      <div className="col-xl-3 col-lg-3 pt-3">
                        <div>
                          <span>{inputValue?.kmStand}</span>
                        </div>
                      </div>
                    </div>

                    {/* dateofBirth */}
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">
                        Geburtsdatum :
                      </label>
                      <div className="col-xl-3 col-lg-3 pt-3">
                        <div>
                          <span>{inputValue?.dateofBirth}</span>
                        </div>
                      </div>
                    </div>

                    {/* street */}
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">
                        Straße :
                      </label>
                      <div className="col-xl-3 col-lg-3 pt-3">
                        <div>
                          <span>{inputValue?.street}</span>
                        </div>
                      </div>
                    </div>

                    {/* telCar */}
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">
                        Telcar :
                      </label>
                      <div className="col-xl-3 col-lg-3 pt-3">
                        <div>
                          <span>{inputValue?.telCar}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </List>
        </Dialog>
      ) : null}

    </>
  );
};

export default Reportdamage;
