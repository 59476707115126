/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { checkIsActive } from "../../../../_helpers";
import { getUserInfo } from "../../../../../utils/user.util";

export function AsideMenuList({ layoutProps }: any) {
  const location = useLocation();
  let userInfo = getUserInfo();
  const getMenuItemActive = (url: any, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };

  return (
    <>
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {userInfo?.role === "superadmin" && (
          <>
          <li className={`menu-item ${getMenuItemActive("/dashboard", false)}`} aria-haspopup="true">
              <NavLink className="menu-link" to="/dashboard">
                <span className="svg-icon menu-icon">
                   {/* <i className="fa-solid fa-building" style={{ color: "#383839" }}></i> */}
                   <i className="fa fa-microchip"  style={{ color: "#383839" }} aria-hidden="true"></i>
                </span>
                <span className="menu-text">Dashboard</span>
              </NavLink>
            </li>
            <li className={`menu-item ${getMenuItemActive("/inquiry", false)}`} aria-haspopup="true">
              <NavLink className="menu-link" to="/inquiry">
                <span className="svg-icon menu-icon">
                  {/* <i className="fa fa-share-square" style={{ color: "#383839" }}></i> */}
                   <i className="fa fa-info-circle"  style={{ color: "#383839" }} aria-hidden="true"></i>
                </span>
                <span className="menu-text">Anfrage</span>
              </NavLink>
            </li>
          
            <li className={`menu-item ${getMenuItemActive("/reportdamage", false)}`} aria-haspopup="true">
              <NavLink className="menu-link" to="/reportdamage">
                <span className="svg-icon menu-icon">
                  
                   <i className="fa fa-microchip"  style={{ color: "#383839" }} aria-hidden="true"></i>
                </span>
                <span className="menu-text">Gemeldete Schäden</span>
              </NavLink>
            </li>

            <li className={`menu-item ${getMenuItemActive("/reruestevbnumber", false)}`} aria-haspopup="true">
              <NavLink className="menu-link" to="/reruestevbnumber">
                <span className="svg-icon menu-icon">
                  {/* <i className="fa-solid fa-building" style={{ color: "#383839" }}></i> */}
                  <i className="fa fa-etsy"  style={{ color: "#383839" }} aria-hidden="true"></i>
                </span>
                <span className="menu-text">EVB - Nummer anfordern</span>
              </NavLink>
            </li>

            <li className={`menu-item ${getMenuItemActive("/contact", false)}`} aria-haspopup="true">
              <NavLink className="menu-link" to="/contact">
                <span className="svg-icon menu-icon">
                <i className="fa-solid fa-building" style={{ color: "#383839" }}></i>
                </span>
                <span className="menu-text">Kontakt</span>
              </NavLink>
            </li>

            <li className={`menu-item ${getMenuItemActive("/socialmedia", false)}`} aria-haspopup="true">
              <NavLink className="menu-link" to="/socialmedia">
                <span className="svg-icon menu-icon">
                  {/* <i className="fa-solid fa-building" style={{ color: "#383839" }}></i> */}
                  <i className="fa fa-flickr"  style={{ color: "#383839" }} aria-hidden="true"></i>
                </span>
                <span className="menu-text">Social Media</span>
              </NavLink>
            </li>

            <li className={`menu-item ${getMenuItemActive("/Homewelcomtext", false)}`} aria-haspopup="true">
              <NavLink className="menu-link" to="/Homewelcomtext">
                <span className="svg-icon menu-icon">
                  {/* <i className="fa-solid fa-building" style={{ color: "#383839" }}></i> */}
                  <i className="fa fa-home"  style={{ color: "#383839" }} aria-hidden="true"></i>
                </span>
                <span className="menu-text">Home Screen</span>
              </NavLink>
            </li>

            <li className={`menu-item ${getMenuItemActive("/Homefeatures", false)}`} aria-haspopup="true">
              <NavLink className="menu-link" to="/Homefeatures">
                <span className="svg-icon menu-icon">
                  {/* <i className="fa-solid fa-building" style={{ color: "#383839" }}></i> */}
                  <i className="fa fa-ravelry"  style={{ color: "#383839" }} aria-hidden="true"></i>
                </span>
                <span className="menu-text">Home Screen Texte </span>
              </NavLink>
            </li>

            <li className={`menu-item ${getMenuItemActive("/Commercialcustomers", false)}`} aria-haspopup="true">
              <NavLink className="menu-link" to="/Commercialcustomers">
                <span className="svg-icon menu-icon">
                  {/* <i className="fa-solid fa-building" style={{ color: "#383839" }}></i> */}
                  <i className="fa fa-align-justify"  style={{ color: "#383839" }} aria-hidden="true"></i>
                </span>
                <span className="menu-text">Referenzen</span>
              </NavLink>
            </li>

            <li className={`menu-item ${getMenuItemActive("/Teaminfo", false)}`} aria-haspopup="true">
              <NavLink className="menu-link" to="/Teaminfo">
                <span className="svg-icon menu-icon">
                  {/* <i className="fa-solid fa-building" style={{ color: "#383839" }}></i> */}
                  <i className="fa fa-question"  style={{ color: "#383839" }} aria-hidden="true"></i>
                </span>
                <span className="menu-text">Team</span>
              </NavLink>
            </li>
          </>
        )}
      </ul>
    </>
  );
}
