import React, { Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";
import { ContentRoute, LayoutSplashScreen } from "../_metronic/layout";
import { getUserInfo } from "../utils/user.util";
import Inquiry from "../_metronic/components/Inquiry/Inquiry";
import Reportdamage from "../_metronic/components/Reportdamage/reportdamage";
import RequestEVBnumber from "../_metronic/components/RequstEVBnumber/RequestEVBnumber";
import Contact from "../_metronic/components/Contact/Contact";
import Socialmedia from "../_metronic/components/Social Media/Social Media";
import Homefeatures from "../_metronic/components/Homefeatures/Homefeatures";
import Homewelcomtext from "../_metronic/components/Homewelcometext/Homewelcometext";
import Commercialcustomers from "../_metronic/components/Commercialcustomers/Commarcialcustomers";
import Teaminfo from "../_metronic/components/Teaminfo/Teaminfo";
import DashboardPage from "./pages/DashboardPage";


export default function BasePage() {
  const UserInfo = getUserInfo();
  return (
    <>
      <Suspense fallback={<LayoutSplashScreen />}>
        <Switch>
          {UserInfo?.role === "superadmin" ? (
            <>
              <Redirect exact from="/" to="/dashboard" />
              <ContentRoute
                exact
                path="/dashboard"
                component={DashboardPage}
                children={undefined}
                render={undefined}
              />
              <ContentRoute
                exact
                path="/inquiry"
                component={Inquiry}
                children={undefined}
                render={undefined}
              />
                <ContentRoute
                exact
                path="/reportdamage"
                component={Reportdamage}
                children={undefined}
                render={undefined}
              />
                <ContentRoute
                exact
                path="/reruestevbnumber"
                component={RequestEVBnumber}
                children={undefined}
                render={undefined}
              />
                <ContentRoute
                exact
                path="/contact"
                component={Contact}
                children={undefined}
                render={undefined}
              />
              <ContentRoute
                exact
                path="/socialmedia"
                component={Socialmedia}
                children={undefined}
                render={undefined}
              />
              <ContentRoute
                exact
                path="/Homewelcomtext"
                component={Homewelcomtext}
                children={undefined}
                render={undefined}
              />
              <ContentRoute
                exact
                path="/Homefeatures"
                component={Homefeatures}
                children={undefined}
                render={undefined}
              />
              <ContentRoute
                exact
                path="/Commercialcustomers"
                component={Commercialcustomers}
                children={undefined}
                render={undefined}
              />
              <ContentRoute
                exact
                path="/Teaminfo"
                component={Teaminfo}
                children={undefined}
                render={undefined}
              />
            </>
          ) : (
            <>
              <Redirect exact from="/" to="/dashboard" />
              {/* <Redirect to="error/error-v6" /> */}
              {/* <ContentRoute
                exact
                path="/shops"
                component={Shops}
                children={undefined}
                render={undefined}
              /> */}
            </>
          )}
          <Redirect to="error/error-v6" />
        </Switch>
      </Suspense>
    </>
  );
}
