import React, { useState } from "react"; //
import { useHistory } from "react-router-dom";
import { ApiPost } from "../../../../helpers/API/ApiData";
import * as authUtil from "../../../../utils/auth.util";
import * as userUtil from "../../../../utils/user.util";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../../../_metronic/_assets/sass/layout/_basic.scss";
import Logo from "../../../../_metronic/layout/components/Logos/Vector.svg";

export default function Login() {
  // const [loading, setLoading] = useState(false);
  const history = useHistory<any>();
  const [loginData, setLoginData] = useState<any>({});
  const [errors, setErrors] = useState<any>({});
  const [loading, setLoading] = useState<any>(false);
  const [loader, setLoader] = useState<any>(false);
  const regexEmail =
    /^(([^<>()[\],;:\s@]+([^<>()[\],;:\s@]+)*)|(.+))@(([^<>()[\],;:\s@]+)+[^<>()[\],;:\s@]{2,})$/i;

  const handleChange = (e: any) => {
    setLoginData({ ...loginData, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: "" });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    if (!loginData.email && !loginData.password) {
      setLoading(false);
      setErrors({
        email: "E-Mail ist erforderlich*",
        password: "Passwort wird benötigt*",
      });
    } else if (loginData.email === "" && loginData.password === "") {
      setErrors({ ...errors, email: "E-Mail ist erforderlich*" });
      setLoading(false);
    } else if (!loginData.email || loginData.email === "") {
      setErrors({ ...errors, email: "E-Mail ist erforderlich*" });
      setLoading(false);
    } else if (!loginData.email || regexEmail.test(loginData.email) === false) {
      setErrors({ ...errors, email: "Email ist ungültig*" });
      setLoading(false);
    } else if (!loginData.password || loginData.password === "") {
      setErrors({ ...errors, password: "Passwort wird benötigt*" });
      setLoading(false);
    } else {
      loginData.email = loginData.email.toLowerCase();

      await ApiPost("admin/login", loginData)
        .then((res: any) => {
          console.log("testlogin", res);
          setLoading(false);

          if (res.data.error === "Email existiert nicht") {
            setErrors({ user: "Benutzer existiert nicht !!" });
            window.location.reload();
          } else if (res?.data?.error === "Falsches Passwort") {
            setErrors({
              user: "Login -Anmeldeinformationen sind falsch !!",
            });
          } else if (res?.data?.payload?.admin?.role === "admin") {
            toast.error("Benutzer existiert nicht");
          } else {
            {
              res?.data?.payload?.admin?.role === "superadmin" &&
                authUtil.setToken(res?.data?.payload?.token);
              userUtil.setUserInfo(res?.data?.payload?.admin);
            }
            window.location.reload();
            toast.success("successfuly login");
            history.push("/dashboard");
          }
        })
        .catch((err) => {
          console.log("err--------->", err.response.data.message);
          toast.error(err.response.data.message);
          setLoading(false);
        });
    }
    // setLoader(false);
  };

  const handleForgotPass = (e: any) => {
    console.log("forgot");
    history.push("/auth/forgot-password");
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
      />

      <div className="login-form login-signin" id="kt_login_signin_form">
        <div className="text-center mb-10 mb-lg-20">
          <h3 className="font-size-h1">SAYAHI | PARTNER</h3>
          {/* <img className="text-light" alt="" src={Logo} width="300px" ></img>  */}
          <p className="text-muted font-weight-bold">
            Geben Sie Ihre Anmeldeinformationen ein.
          </p>
          <span className="text-danger h6">{errors.user}</span>
        </div>

        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="E-Mail"
            type="email"
            className={`form-control form-control-solid h-auto py-5 px-6  `}
            name="email"
            onChange={(e) => {
              handleChange(e);
            }}
            onKeyDown={(e) => e.key == "Enter" && handleSubmit(e)}
          />
          <span className="text-danger">{errors.email}</span>
        </div>
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Passwort"
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 `}
            name="password"
            onChange={(e) => {
              handleChange(e);
            }}
            onKeyDown={(e) => e.key == "Enter" && handleSubmit(e)}
          />
          <span className="text-danger">{errors.password}</span>
        </div>

        {/* <div onClick={(e) => handleForgotPass(e)} style={{ textAlign: "right" }}>
        Passwort vergessen?
      </div> */}

        <div className="form-group d-flex flex-wrap justify-content-center align-items-center">
          <button
            id="kt_login_signin_submit"
            type="submit"
            className={`align-items-center d-flex btn btncolor font-weight-bold px-9 py-4 my-3`}
            onClick={(e) => {
              handleSubmit(e);
            }}
            // style={{background:"#1BC5BD"}}
          >
            <span className="pr-2">Einloggen</span>

            {loading && <span className="mx-3 spinner spinner-white"></span>}
            {/* {loader && (
            <div class="spinner-grow text-light" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          )} */}
          </button>
        </div>
        {/* <div className="d-flex justify-content-center">
          <span className="font-weight-bold text-dark-50">
            Don't have an account yet?
          </span>
          <Link
            to="/auth/registration"
            className="font-weight-bold ml-2"
            id="kt_login_signup"
          >
            Sign Up!
          </Link>
        </div> */}
        {/* <div className="d-flex justify-content-center">
          <span className="font-weight-bold text-dark-50">
            Read our <Link>Onboarding Policy</Link> here.
          </span>
        </div> */}
        {/* </form> */}

        {/*end::Form*/}
      </div>
    </>
  );
}
