import React, { useEffect, useState } from "react";
import { title } from "process";
import { Button, Modal } from "react-bootstrap";
import DataTable, { defaultThemes } from "react-data-table-component";
import { toast, ToastContainer } from "react-toastify";
import { ApiDelete, ApiGet, ApiPost, ApiPut } from "../../../helpers/API/ApiData";
import { Dialog, IconButton, List, Slide, Toolbar, Tooltip } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CreateIcon from "@material-ui/icons/Create";
import InfoIcon from "@material-ui/icons/Info";
import DeleteIcon from "@material-ui/icons/Delete";
import { ThreeCircles } from "react-loader-spinner";
import { log } from "console";

const Transition: any = React.forwardRef(function Transition(props: any, ref: any) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Teaminfo = () => {
  const [isLoaderVisible, setIsLoaderVisible] = useState<boolean>(false);
  const [isDeleteApi, setIsDeleteApi] = useState<boolean>(false);
  const [isEditApi, setIsEditApi] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isAdd, setIsAdd] = useState<boolean>(false);
  const [isinfo, setIsinfo] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [countPerPage, setCountPerPage] = useState<number>(10);
  const [count, setCount] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [getteaminfo, setGetteaminfo] = useState<any>([]);
  const [idForUpdate, setIdForUpdate] = useState<string>("");
  const [idForDelete, setIdForDelete] = useState<string>("");
  const [inputValue, setInputValue] = useState<any>({});
  const [filterData, setFilterData] = useState<any>();
  const [errors, setErrors] = useState<any>({});
  const [idForinfo, setIdForinfo] = useState<any>();
  const [getteaminformation, setGetteaminformation] = useState<any>({});
  const [serachbox, setSerachbox] = useState<any>("");

  useEffect(() => {
    title === "Sayahi | Dashboard"
      ? (document.title = title)
      : (document.title = "SAYAHI | Team Info");
  }, []);

  useEffect(() => {
    getAllteaminfo();
  }, [page, countPerPage]);

  const handleOnChange = (e: any) => {
    const { name, value } = e.target;
    setInputValue({ ...inputValue, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };
  const handleClose = () => {
    setInputValue({});
    setErrors({});
    setIsAdd(false);
    setIsEditApi(false);
    setIsinfo(false);
    setIsDeleteApi(false);

  };
  const validateFormForAdd = () => {
    let formIsValid = true;
    let errors: any = {};
    if (inputValue && !inputValue.name) {
      formIsValid = false;
      errors["name"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (inputValue && !inputValue.email) {
      formIsValid = false;
      errors["email"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (inputValue && !inputValue.telephone) {
      formIsValid = false;
      errors["telephone"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (inputValue && !inputValue.position) {
      formIsValid = false;
      errors["position"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (inputValue && !inputValue.educationAndProfessionalCareer) {
      formIsValid = false;
      errors["educationAndProfessionalCareer"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (inputValue && !inputValue.qualification) {
      formIsValid = false;
      errors["qualification"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (inputValue && !inputValue.knownFor) {
      formIsValid = false;
      errors["knownFor"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (inputValue && !inputValue.atSayahiSays) {
      formIsValid = false;
      errors["atSayahiSays"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (inputValue && !inputValue.telephone) {
      formIsValid = false;
      errors["telephone"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    // if (inputValue && !inputValue.image) {
    //   formIsValid = false;
    //   errors["image"] = "Bitte überprüfen Sie ihre Eingabe*";
    // }
    setErrors(errors);
    return formIsValid;
  };
  const validateFormUpdate = () => {
    let formIsValid = true;
    let errors: any = {};
    if (inputValue && !inputValue.name) {
      formIsValid = false;
      errors["name"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (inputValue && !inputValue.email) {
      formIsValid = false;
      errors["email"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (inputValue && !inputValue.telephone) {
      formIsValid = false;
      errors["telephone"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (inputValue && !inputValue.position) {
      formIsValid = false;
      errors["position"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (inputValue && !inputValue.educationAndProfessionalCareer) {
      formIsValid = false;
      errors["educationAndProfessionalCareer"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (inputValue && !inputValue.qualification) {
      formIsValid = false;
      errors["qualification"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (inputValue && !inputValue.knownFor) {
      formIsValid = false;
      errors["knownFor"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (inputValue && !inputValue.atSayahiSays) {
      formIsValid = false;
      errors["atSayahiSays"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (inputValue && !inputValue.telephone) {
      formIsValid = false;
      errors["telephone"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    // if (inputValue && !inputValue.image) {
    //   formIsValid = false;
    //   errors["image"] = "Bitte überprüfen Sie ihre Eingabe*";
    // }
    setErrors(errors);
    return formIsValid;
  };
  const handleOnChangeImg = (e: any) => {
    const { name } = e.target;
    setInputValue({ ...inputValue, [name]: e.target.files[0] });
    setErrors({ ...errors, [name]: "" });
  };
  const getAllteaminfo = async () => {
    setIsLoaderVisible(true);
    await ApiGet(`teamInfo/getTeamInfo`)
      .then((res: any) => {
        console.log("res", res);
        setIsLoaderVisible(false);
        setGetteaminfo(res?.data?.payload?.teamInfo);
        // setFilterData(res?.data?.data);
        // setCount(res?.data?.data?.length);
        // toast.success(res?.data?.message)
      })
      .catch((err) => { });
  };

  const Getteaminformation = async (e: any) => {
    setIsLoaderVisible(true);
    await ApiGet(`teamInfo/getTeamInfo`)
      .then((res: any) => {
        console.log("res", res);
        setIsLoaderVisible(false);
        setGetteaminformation(res?.data?.payload?.teamInfo);
      })
      .catch((err) => { });
  };

  // for add data
  const handleAdd = (e: any) => {
    e.preventDefault();
    if (validateFormForAdd()) {
      setLoading(true);
      let formData = new FormData();
      formData.append("name", inputValue?.name.trim());
      formData.append("email", inputValue?.email);
      formData.append("telephone", inputValue?.telephone);
      formData.append("position", inputValue?.position);
      formData.append("qualification", inputValue?.qualification);
      formData.append("educationAndProfessionalCareer", inputValue?.educationAndProfessionalCareer);
      formData.append("knownFor", inputValue?.knownFor);
      formData.append("atSayahiSays", inputValue?.atSayahiSays);
      formData.append("image", inputValue?.image);

      console.log("formData", formData);



      ApiPost(`teamInfo/addTeamInfo`, formData)
        .then((res: any) => {
          if (res?.status == 200) {
            console.log("addres", res);
            setIsAdd(false);
            setIsinfo(false);
            // toast.success(res?.data?.message);
            setInputValue({});
            getAllteaminfo();
            setLoading(false);
          } else {
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };
  // for update data
  const handleUpdate = (e: any) => {
    e.preventDefault();
    if (validateFormUpdate()) {
      setLoading(true);
      let formData = new FormData();
      formData.append("name", inputValue?.name);
      formData.append("email", inputValue?.email);
      formData.append("telephone", inputValue?.telephone);
      formData.append("position", inputValue?.position);
      formData.append("qualification", inputValue?.qualification);
      formData.append("educationAndProfessionalCareer", inputValue?.educationAndProfessionalCareer);
      formData.append("knownFor", inputValue?.knownFor);
      formData.append("atSayahiSays", inputValue?.atSayahiSays);
      formData.append("image", inputValue?.image);
      ApiPut(`teamInfo/updateTeamInfo/${idForUpdate}`, formData)
        .then((res: any) => {
          if (res?.status == 200) {
            setInputValue({});
            setIsAdd(false);
            setIsEditApi(false);

            toast.success(res?.data?.message);
            getAllteaminfo();
            setLoading(false);
          } else {
            toast.error(res?.data?.message);
            setLoading(false);
          }
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
          setLoading(false);
        });
    }
  };

  const deleteTeamData = async () => {
    setLoading(true);
    const deleteData: any = {
      isActive: false
    }
    await ApiPut(`teamInfo/updateTeamInfo/${idForDelete}`, deleteData)
      .then((res: any) => {
        if (res?.status == 200) {
          setIsDeleteApi(false);
          toast.success(res?.data?.message);
          getAllteaminfo();
          setLoading(false);
        } else {
          toast.error(res?.data?.message);
          setLoading(false);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        setLoading(false);
      });

  }

  const columns: any = [
    {
      name: "Nr.",
      cell: (row: any, index: any) => (page - 1) * countPerPage + (index + 1),
      width: "20px",
    },
    {
      name: "Name",
      selector: (row: any) => row?.name,
      sortable: true,
    },
    // {
    //   name: "position",
    //   selector: (row: any) => row?.position,
    //   width: "90px",
    // },
    {
      name: "Telefon",
      selector: (row: any) => row?.telephone,
      sortable: true,
    },
    {
      name: "E-Mail",
      selector: (row: any) => row?.email,
      sortable: true,
    },
    {
      name: "Qualifikation",
      selector: (row: any) => row?.qualification,
      sortable: true,
    },
    // {
    //   name: "educationAndProfessionalCareer",
    //   selector: (row: any) => row?.educationAndProfessionalCareer,
    //   width: "150px",
    // },
    // {
    //   name: "knownFor",
    //   selector: (row: any) => row?.knownFor,
    //   width: "100px",
    // },
    // {
    //   name: "atSayahiSays",
    //   selector: (row: any) => row?.atSayahiSays,
    //   width: "150px",
    // },
    {
      name: "Bild",
      cell: (row: any) => {
        return (
          <>
            <div className="mt-4 mb-4 p-1 border border-dark rounded">
              <img src={row?.image} width={100} height={50} />
            </div>
          </>
        );
      },
      sortable: true,
    },
    {
      name: "Aktionen",
      cell: (row: any) => {
        return (
          <>
            <div className="d-flex justify-content-between">
              <div
                className="cursor-pointer pl-2"
                onClick={() => {
                  setIsAdd(true);
                  setIsEditApi(true);
                  setInputValue({
                    name: row?.name,
                    email: row?.email,
                    telephone: row?.telephone,
                    position: row?.position,
                    qualification: row?.qualification,
                    educationAndProfessionalCareer: row?.educationAndProfessionalCareer,
                    knownFor: row?.knownFor,
                    atSayahiSays: row?.atSayahiSays,
                    image: row?.image
                  });
                  setIdForUpdate(row?._id);
                }}
              >
                <Tooltip title="Bearbeiten" arrow>
                  <CreateIcon />
                </Tooltip>
              </div>
              <div>
                <div
                  className="cursor-pointer pl-2"
                  onClick={() => {
                    setIsinfo(true);
                    setInputValue({
                      name: row?.name,
                      email: row?.email,
                      telephone: row?.telephone,
                      position: row?.position,
                      qualification: row?.qualification,
                      educationAndProfessionalCareer: row?.educationAndProfessionalCareer,
                      knownFor: row?.knownFor,
                      atSayahiSays: row?.atSayahiSays,
                      image: row?.image
                    });
                  }}
                >
                  <Tooltip title="Information" arrow>
                    <InfoIcon />
                  </Tooltip>
                </div>
              </div>
            </div>
            <div
              className="cursor-pointer"
              onClick={() => {
                setShow(true);
                // setIdForDelete(row?._id);
              }}
            >
            </div>

            <div
              className="cursor-pointer pl-2"
              onClick={() => {

                setIsDeleteApi(true);
                setIdForDelete(row?._id);
              }}
            >
              <Tooltip title="Löschen" arrow>
                <DeleteIcon />
              </Tooltip>
            </div>

          </>
        );
      },
    },

  ];

  const SearchfilterData = getteaminfo && getteaminfo?.filter((el: any, index: any) => {
    if (serachbox === "") {
      return el;
    } else if (serachbox !== "") {
      let fullName = `${el.name}`;
      let res = fullName.toLowerCase();
      return res.match(serachbox.toLowerCase());
    }
  });

  const handleSearch = (e: any) => {
    var value = e.target.value;
    setSerachbox(value)
  };
  // * Table Style
  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: defaultThemes.default.divider.default,
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };

  return (
    <>
      <ToastContainer />
      <div className="card p-1">
        {document.title === "Sayahi | Report Damage" && <ToastContainer />}
        <div className="p-2 mb-2">
          <div className="row mb-4 pr-3">
            <div className="col d-flex justify-content-between">
              <h2 className="pl-3 pt-2">Team</h2>
            </div>
            <div className="col">
              <div>
                <input
                  type="text"
                  className={`form-control form-control-solid `}
                  name="search"
                  // value={search}
                  placeholder="Team suchen…"
                  onChange={(e) => handleSearch(e)}
                />
              </div>
            </div>
            <div className="cus-medium-button-style button-height">
              <button
                onClick={() => {
                  setIsAdd(true);
                }}
                className="btn btn-success mr-2"
              >
                Hinzufügen
              </button>
            </div>
          </div>

          {/*close*/}
          <Modal show={isDeleteApi} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title className="text-danger">Alert!</Modal.Title>
            </Modal.Header>
            <Modal.Body>Sind Sie sicher, dass Sie dieses Teaminfo löschen möchten?</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
              Abbrechen
              </Button>
              <Button variant="danger" onClick={() => deleteTeamData()}>
              Löschen
              </Button>
            </Modal.Footer>
          </Modal>

          <DataTable
            columns={columns}
            data={SearchfilterData}
            customStyles={customStyles}
            style={{
              marginTop: "-3rem",
            }}
            progressPending={isLoaderVisible}
            progressComponent={<ThreeCircles color="#334D52" height={30} width={30} />}
            highlightOnHover
            pagination
              // paginationServer
              // paginationTotalRows={count}
              paginationPerPage={countPerPage}
              paginationRowsPerPageOptions={[10, 20, 25, 50, 100]}
              paginationDefaultPage={page}
              onChangePage={(page) => {
                setPage(page);
              }}
              onChangeRowsPerPage={(rowPerPage) => {
                setCountPerPage(rowPerPage);
              }}
            // pagination
            // paginationServer
            // paginationTotalRows={count}
            // paginationPerPage={countPerPage}
            // paginationRowsPerPageOptions={[10, 20, 25, 50, 100]}
            // paginationDefaultPage={page}
            // onChangePage={(page) => {
            //   setPage(page);
            // }}
            // onChangeRowsPerPage={(rowPerPage) => {
            //   setCountPerPage(rowPerPage);
            // }}
          />
        </div>
      </div>

      {isAdd ? (
        <Dialog fullScreen open={isAdd} onClose={handleClose} TransitionComponent={Transition}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
          <List>
            {isAdd === true && (
              <div className="form ml-30 ">

                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">Name :</label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <input
                        type="text"
                        className={`form-control form-control-lg form-control-solid `}
                        id="name"
                        name="name"
                        value={inputValue?.name}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["name"]}
                    </span>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">E-Mail :</label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <input
                        type="email"
                        className={`form-control form-control-lg form-control-solid `}
                        id="email"
                        name="email"
                        value={inputValue?.email}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["email"]}
                    </span>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">Telefon :</label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <input
                        type="text"
                        className={`form-control form-control-lg form-control-solid `}
                        id="telephone"
                        name="telephone"
                        value={inputValue.telephone}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["telephone"]}
                    </span>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">Position :</label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <input
                        type="text"
                        className={`form-control form-control-lg form-control-solid `}
                        id="position"
                        name="position"
                        value={inputValue.position}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["position"]}
                    </span>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">Qualifikation :</label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <input
                        type="text"
                        className={`form-control form-control-lg form-control-solid `}
                        id="qualification"
                        name="qualification"
                        value={inputValue.qualification}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["qualification"]}
                    </span>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">Ausbildung und berufliche Laufbahn :</label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <input
                        type="text"
                        className={`form-control form-control-lg form-control-solid `}
                        id="educationAndProfessionalCareer"
                        name="educationAndProfessionalCareer"
                        value={inputValue.educationAndProfessionalCareer}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["educationAndProfessionalCareer"]}
                    </span>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">Bekannt zum :</label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <input
                        type="text"
                        className={`form-control form-control-lg form-control-solid `}
                        id="knownFor"
                        name="knownFor"
                        value={inputValue.knownFor}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["knownFor"]}
                    </span>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">Bei SAYAHI | SCHOLZ seit :</label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <input
                        type="text"
                        className={`form-control form-control-lg form-control-solid `}
                        id="atSayahiSays"
                        name="atSayahiSays"
                        value={inputValue.atSayahiSays}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["atSayahiSays"]}
                    </span>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">Bild :</label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <input
                        type="file"
                        className={`form-control form-control-lg form-control-solid `}
                        name="image"
                        onChange={(e) => {
                          handleOnChangeImg(e);
                        }}
                        accept="image/*"
                        required
                      />
                    </div>

                    <div>
                      {inputValue?.image && (
                        <img
                          style={{
                            height: "128px",
                            width: "128px",
                            margin: "15px 0",
                            objectFit: "contain",
                          }}
                          src={
                            inputValue?.image?.toString()?.includes(".com") === true
                              ? inputValue?.image
                              : URL.createObjectURL(inputValue?.image)
                          }
                          alt=""
                        />
                      )}
                    </div>

                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["image"]}
                    </span>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-center">
                  {loading ? (
                    <button className="btn btn-success mr-2">
                      <span>{isEditApi ? "Speichern" : "Speichern"}</span>
                      {loading && <span className="mx-3 spinner spinner-white"></span>}
                    </button>
                  ) : (
                    <button
                      onClick={(e) => {
                        isEditApi ? handleUpdate(e) : handleAdd(e);
                      }}
                      className="btn btn-success mr-2"
                    >
                      <span>{isEditApi ? "Speichern" : "Speichern"}</span>
                      {loading && <span className="mx-3 spinner spinner-white"></span>}
                    </button>
                  )}
                </div>
              </div>
            )}
          </List>
        </Dialog>
      ) : null}


      {isinfo ? (
        <Dialog fullScreen open={isinfo} onClose={handleClose} TransitionComponent={Transition}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
          <List>
            {isinfo && (
              <>

                <div className="ml-40">
                  <div className="form cus-container" >

                    {/* Name */}
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">
                        Name :
                      </label>
                      <div className="col-lg-9 col-xl-6 pt-3">
                        <div>
                          <span>{inputValue.name}</span>
                        </div>
                      </div>
                    </div>

                    {/* Email */}
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">
                      E-Mail :
                      </label>
                      <div className="col-xl-3 col-lg-3 pt-3">
                        <div>
                          <span>{inputValue?.email}</span>
                        </div>
                      </div>
                    </div>

                    {/* telephone */}
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">
                        Telefon :
                      </label>
                      <div className="col-xl-3 col-lg-3 pt-3">
                        <div>
                          <span>{inputValue?.telephone}</span>
                        </div>
                      </div>
                    </div>

                    {/* position */}
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">
                      Position :
                      </label>
                      <div className="col-xl-3 col-lg-3 pt-3">
                        <div>
                          <span>{inputValue?.position}</span>
                        </div>
                      </div>
                    </div>

                    {/* qualification */}
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">
                        Qualifikation :
                      </label>
                      <div className="col-xl-3 col-lg-3 pt-3">
                        <div>
                          <span>{inputValue?.qualification}</span>
                        </div>
                      </div>
                    </div>

                    {/* educationAndProfessionalCareer */}
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">
                      Ausbildung und berufliche Laufbahn :
                      </label>
                      <div className="col-xl-3 col-lg-3 pt-3">
                        <div>
                          <span>{inputValue?.educationAndProfessionalCareer}</span>
                        </div>
                      </div>
                    </div>

                    {/* knownFor */}
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">
                      Bekannt zum :
                      </label>
                      <div className="col-xl-3 col-lg-3 pt-3">
                        <div>
                          <span>{inputValue?.knownFor}</span>
                        </div>
                      </div>
                    </div>

                    {/* atSayahiSays */}
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">
                      Bei SAYAHI | SCHOLZ seit :
                      </label>
                      <div className="col-xl-3 col-lg-3 pt-3">
                        <div>
                          <span>{inputValue?.atSayahiSays}</span>
                        </div>
                      </div>
                    </div>

                    {/* image */}
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">
                        Bild :
                      </label>
                      <div className="col-xl-3 col-lg-3 pt-3">
                        <div>
                          <span>  <div>
                            {inputValue?.image && (
                              <img
                                style={{
                                  height: "200px",
                                  width: "250px",
                                  objectFit: "contain",
                                }}
                                src={
                                  inputValue?.image?.toString()?.includes(".com") === true
                                    ? inputValue?.image
                                    : URL.createObjectURL(inputValue?.image)
                                }
                                alt=""
                              />
                            )}
                          </div></span>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </>
            )}
          </List>
        </Dialog>
      ) : null}


    </>
  );
};

export default Teaminfo;



