import React, { useEffect, useState } from "react";
import { title } from "process";
import { Button, Modal } from "react-bootstrap";
import DataTable, { defaultThemes } from "react-data-table-component";
import { toast, ToastContainer } from "react-toastify";
import {
  ApiDelete,
  ApiGet,
  ApiPost,
  ApiPut,
} from "../../../helpers/API/ApiData";
import {
  Dialog,
  IconButton,
  List,
  Slide,
  Toolbar,
  Tooltip,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import { ThreeCircles } from "react-loader-spinner";
import InfoIcon from "@material-ui/icons/Info";

const Transition: any = React.forwardRef(function Transition(
  props: any,
  ref: any
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Inquiry = () => {
  const [isLoaderVisible, setIsLoaderVisible] = useState<boolean>(false);
  const [isEditApi, setIsEditApi] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isAdd, setIsAdd] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [countPerPage, setCountPerPage] = useState<number>(10);
  const [count, setCount] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [getinquiry, setGetinquiry] = useState<any>([]);
  const [idForUpdate, setIdForUpdate] = useState<string>("");
  const [idForDelete, setIdForDelete] = useState<string>("");
  const [inputValue, setInputValue] = useState<any>({});
  const [filterData, setFilterData] = useState<any>();
  const [errors, setErrors] = useState<any>({});
  const [serachbox, setSerachbox] = useState<any>("");

  const [isinfo, setIsinfo] = useState<any>(false);
  const [idFordeleteaboutapp, setIdForDeleteAboutApp] = useState<any>();

  useEffect(() => {
    title === "Sayahi | Dashboard"
      ? (document.title = title)
      : (document.title = "Sayahi | Inquiry");
  }, []);

  useEffect(() => {
    getAllinquiry();
  }, [page, countPerPage]);

  const handleClose = () => {
    setInputValue({});
    setErrors({});
    setIsAdd(false);
    setIsEditApi(false);
    setIsinfo(false);
  };
  const handleCloseDelete = () => {
    setShow(false);
  };

  const validateFormUpdate = () => {
    let formIsValid = true;
    let errors: any = {};
    if (!inputValue || inputValue.name.trim() === "") {
      formIsValid = false;
      errors["name"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (inputValue && !inputValue.email) {
      formIsValid = false;
      errors["email"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (inputValue && !inputValue.telephone) {
      formIsValid = false;
      errors["telephone"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (inputValue && !inputValue.plan) {
      formIsValid = false;
      errors["plan"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (inputValue && !inputValue.object) {
      formIsValid = false;
      errors["object"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (inputValue && !inputValue.objectPrice) {
      formIsValid = false;
      errors["objectPrice"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (inputValue && !inputValue.use) {
      formIsValid = false;
      errors["use"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (inputValue && !inputValue.loanAmmount) {
      formIsValid = false;
      errors["loanAmmount"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (inputValue && !inputValue.salutation) {
      formIsValid = false;
      errors["salutation"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (inputValue && !inputValue.fax) {
      formIsValid = false;
      errors["fax"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (inputValue && !inputValue.bussiness) {
      formIsValid = false;
      errors["bussiness"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (inputValue && !inputValue.fixedInterestRate) {
      formIsValid = false;
      errors["fixedInterestRate"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (inputValue && !inputValue.Strasse) {
      formIsValid = false;
      errors["Strasse"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (inputValue && !inputValue.plz) {
      formIsValid = false;
      errors["plz"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (inputValue && !inputValue.ort) {
      formIsValid = false;
      errors["ort"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (inputValue && !inputValue.professionalStatus) {
      formIsValid = false;
      errors["professionalStatus"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (inputValue && !inputValue.remarks) {
      formIsValid = false;
      errors["remarks"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    setErrors(errors);
    return formIsValid;
  };
  const handleOnChange = (e: any) => {
    const { name, value } = e.target;
    setInputValue({ ...inputValue, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const getAllinquiry = async () => {
    setIsLoaderVisible(true);
    await ApiGet(`inquiry/getInquiry`)
      .then((res: any) => {
        setIsLoaderVisible(false);
        setGetinquiry(res?.data?.payload?.inquiry);
        // setFilterData(res?.data?.data);
        // setCount(res?.data?.data?.length);
        // toast.success(res?.data?.message)
      })
      .catch((err) => {});
  };

  const handleUpdate = (e: any) => {
    e.preventDefault();
    if (validateFormUpdate()) {
      setLoading(true);
      let data = {
        name: inputValue?.name,
        plan: inputValue?.plan,
        email: inputValue?.email,
        object: inputValue?.object,
        use: inputValue?.use,
        objectPrice: inputValue?.objectPrice,
        loanAmmount: inputValue?.loanAmmount,
        fixedInterestRate: inputValue?.fixedInterestRate,
        salutation: inputValue?.salutation,
        bussiness: inputValue?.bussiness,
        Strasse: inputValue?.Strasse,
        plz: inputValue?.plz,
        ort: inputValue?.ort,
        telephone: inputValue?.telephone,
        fax: inputValue?.fax,
        professionalStatus: inputValue?.professionalStatus,
        remarks: inputValue?.remarks,
      };
      ApiPut(`inquiry/updateInquiry/${idForUpdate}`, data)
        .then((res: any) => {
          if (res?.status == 200) {
            setInputValue({});
            setIsAdd(false);
            toast.success(res?.data?.message);
            getAllinquiry();
            setLoading(false);
          } else {
            toast.error(res?.data?.message);
            setLoading(false);
          }
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
          setLoading(false);
        });
    }
  };
  const handleDelete = () => {
    // e.preventDefault();

    setLoading(true);
    let data = {
      isActive: "false",
    };
    // formData.append("isActive", false);
    ApiPut(`inquiry/updateInquiry/${idFordeleteaboutapp?._id}`, data)
      .then((res: any) => {
        if (res?.status == 200) {
          setShow(false);
          toast.success("Deleted Successfully");
          getAllinquiry();
          setPage(1);
          setCount(0);
          setCountPerPage(countPerPage);
        } else {
          toast.error(res?.data?.message);
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const columns: any = [
    {
      name: "Nr.",
      cell: (row: any, index: any) => (page - 1) * countPerPage + (index + 1),
      width: "65px",
    },
    {
      name: "Name",
      selector: (row: any) => row?.name,
      sortable: true,
    },
    {
      name: "E-Mail",
      selector: (row: any) => row?.email,
      sortable: true,
    },
    // {
    //   name: "use",
    //   selector: (row: any) => row?.use,
    //   sortable: true,
    // },
    {
      name: "Telefon",
      selector: (row: any) => row?.telephone,
      sortable: true,
    },
    // {
    //   name: "salutation",
    //   selector: (row: any) => row?.salutation,
    //   sortable: true,
    // },
    // {
    //   name: "remarks",
    //   selector: (row: any) => row?.remarks,
    //   sortable: true,
    // },
    // {
    //   name: "professionalStatus",
    //   selector: (row: any) => row?.professionalStatus,
    //   sortable: true,
    // },
    // {
    //   name: "plan",
    //   selector: (row: any) => row?.plan,
    //   sortable: true,
    // },
    // {
    //   name: "objectPrice",
    //   selector: (row: any) => row?.objectPrice,
    //   sortable: true,
    // },
    // {
    //   name: "object",
    //   selector: (row: any) => row?.email,
    //   sortable: true,
    // },
    // {
    //   name: "loanAmmount",
    //   selector: (row: any) => row?.loanAmmount,
    //   sortable: true,
    // },
    // {
    //   name: "fixedInterestRate",
    //   selector: (row: any) => row?.fixedInterestRate,
    //   sortable: true,
    // },
    // {
    //   name: "fax",
    //   selector: (row: any) => row?.fax,
    //   sortable: true,
    // },
    // {
    //   name: "bussiness",
    //   selector: (row: any) => row?.bussiness,
    //   sortable: true,
    // },
    // {
    //   name: "Strasse",
    //   selector: (row: any) => row?.Strasse,
    //   sortable: true,
    // },
    // {
    //   name: "plz",
    //   selector: (row: any) => row?.plz,
    //   sortable: true,
    // },
    // {
    //   name: "ort",
    //   selector: (row: any) => row?.ort,
    //   sortable: true,
    // },
    {
      name: "Aktionen",
      cell: (row: any) => {
        return (
          <>
            <div className="d-flex justify-content-between">
              {/* edit button */}
              {/* <div
                className="cursor-pointer pl-2"
                onClick={() => {
                  setIsAdd(true);
                  setInputValue({
                    name: row?.name,
                    email: row?.email,
                    telephone: row?.telephone,
                    plan: row?.plan,
                    object: row?.object,
                    educationAndProfessionalCareer: row?.object,
                    use: row?.use,
                    objectPrice: row?.objectPrice,
                    loanAmmount: row?.loanAmmount,
                    fixedInterestRate : row?.fixedInterestRate,
                    salutation: row?.salutation,
                    bussiness :row?.bussiness,
                    Strasse: row?.Strasse,
                    plz :row?.plz,
                    ort :row?.ort,
                    fax: row?.fax,
                    professionalStatus: row?.professionalStatus,
                    remarks:row?.remarks,
                  });
                  setIdForUpdate(row?._id);
                }}
              >
                <Tooltip title="Edit" arrow>
                  <CreateIcon />
                </Tooltip>
              </div> */}
              {/* info button */}
              <div>
                <div
                  className="cursor-pointer pl-2"
                  onClick={() => {
                    setIsinfo(true);
                    setInputValue({
                      name: row?.name,
                      email: row?.email,
                      telephone: row?.telephone,
                      plan: row?.plan,
                      object: row?.object,
                      educationAndProfessionalCareer: row?.object,
                      use: row?.use,
                      objectPrice: row?.objectPrice,
                      loanAmmount: row?.loanAmmount,
                      fixedInterestRate: row?.fixedInterestRate,
                      salutation: row?.salutation,
                      bussiness: row?.bussiness,
                      Strasse: row?.Strasse,
                      plz: row?.plz,
                      ort: row?.ort,
                      fax: row?.fax,
                      professionalStatus: row?.professionalStatus,
                      remarks: row?.remarks,
                    });
                  }}
                >
                  <Tooltip title="Information" arrow>
                    <InfoIcon />
                  </Tooltip>
                </div>
              </div>
              {/* delete button */}
              <div
                className="cursor-pointer"
                onClick={() => {
                  setShow(true);
                  setIdForDeleteAboutApp(row);
                }}
              >
                <Tooltip title="Löschen" arrow>
                  <DeleteIcon />
                </Tooltip>
              </div>
            </div>
            <div
              className="cursor-pointer"
              onClick={() => {
                setShow(true);
                setIdForDelete(row?._id);
              }}
            ></div>
          </>
        );
      },
    },
  ];

  const SearchfilterData =
    getinquiry &&
    getinquiry?.filter((el: any, index: any) => {
      if (serachbox === "") {
        return el;
      } else if (serachbox !== "") {
        let fullName = `${el.name}`;
        let res = fullName.toLowerCase();
        return res.match(serachbox.toLowerCase());
      }
    });

  const handleSearch = (e: any) => {
    var value = e.target.value;
    setSerachbox(value);
  };

  // * Table Style
  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: defaultThemes.default.divider.default,
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };

  return (
    <>
      <div className="card p-1">
        {document.title === "Sayahi | Inquiry" && <ToastContainer />}
        <div className="p-2 mb-2">
          <div className="row mb-4 pr-3">
            <div className="col d-flex justify-content-between">
              <h2 className="pl-3 pt-2">Anfrage</h2>
            </div>
            <div className="col">
              <div>
                <input
                  type="text"
                  className={`form-control form-control-solid `}
                  name="search"
                  // value={search}
                  placeholder="Anfrage suchen…"
                  onChange={(e) => handleSearch(e)}
                />
              </div>
            </div>
            {/* <div className="cus-medium-button-style button-height">
              <button
                onClick={() => {
                  setIsAdd(true);
                }}
                className="btn btn-success mr-2"
              >
                Add 
              </button>
            </div> */}
          </div>

          {/* delete model */}
          <Modal show={show} onHide={handleCloseDelete}>
            <Modal.Header closeButton>
              <Modal.Title className="text-danger">Alert!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Sind Sie sicher, dass Sie diese Anfrage löschen möchten?
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseDelete}>
                Abbrechen
              </Button>
              <Button
                variant="danger"
                onClick={() => {
                  handleDelete();
                }}
              >
                Löschen
              </Button>
            </Modal.Footer>
          </Modal>
          {/* end delete model */}

          <DataTable
            // noDataComponent="Your Text Here"
            columns={columns}
            data={SearchfilterData}
            customStyles={customStyles}
            style={{
              marginTop: "-3rem",
            }}
            progressPending={isLoaderVisible}
            progressComponent={
              <ThreeCircles color="#334D52" height={30} width={30} />
            }
            highlightOnHover
            pagination
              // paginationServer
              // paginationTotalRows={count}
              paginationPerPage={countPerPage}
              paginationRowsPerPageOptions={[10, 20, 25, 50, 100]}
              paginationDefaultPage={page}
              onChangePage={(page) => {
                setPage(page);
              }}
              onChangeRowsPerPage={(rowPerPage) => {
                setCountPerPage(rowPerPage);
              }}
            // pagination
            // paginationServer
            // paginationTotalRows={count}
            // paginationPerPage={countPerPage}
            // paginationRowsPerPageOptions={[5, 10, 20, 25, 50, 100]}
            // paginationDefaultPage={page}
            // onChangePage={(page) => {
            //   setPage(page);
            // }}
            // onChangeRowsPerPage={(rowPerPage) => {
            //   setCountPerPage(rowPerPage);
            // }}
          />
        </div>
      </div>

      {/* {isAdd ? (
        <Dialog fullScreen open={isAdd} onClose={handleClose} TransitionComponent={Transition}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
          <List>
            {isAdd === true && (
              <div className="form ml-30 "> */}

      {/* name */}
      {/* <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">name</label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <input
                        type="text"
                        className={`form-control form-control-lg form-control-solid `}
                        id="name"
                        name="name"
                        value={inputValue?.name}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["name"]}
                    </span>
                  </div>
                </div> */}

      {/* email */}
      {/* <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">email</label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <input
                        type="email"
                        className={`form-control form-control-lg form-control-solid `}
                        id="email"
                        name="email"
                        value={inputValue?.email}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["email"]}
                    </span>
                  </div>
                </div> */}

      {/* telephone */}
      {/* <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">telephone</label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <input
                        type="text"
                        className={`form-control form-control-lg form-control-solid `}
                        id="telephone"
                        name="telephone"
                        value={inputValue.telephone}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["telephone"]}
                    </span>
                  </div>
                </div> */}

      {/* plan */}
      {/* <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">plan</label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <input
                        type="text"
                        className={`form-control form-control-lg form-control-solid `}
                        id="plan"
                        name="plan"
                        value={inputValue.plan}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["plan"]}
                    </span>
                  </div>
                </div> */}

      {/* object */}
      {/* <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">object</label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <input
                        type="text"
                        className={`form-control form-control-lg form-control-solid `}
                        id="object"
                        name="object"
                        value={inputValue.object}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["object"]}
                    </span>
                  </div>
                </div> */}

      {/* use */}
      {/* <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">use</label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <input
                        type="text"
                        className={`form-control form-control-lg form-control-solid `}
                        id="use"
                        name="use"
                        value={inputValue.use}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["use"]}
                    </span>
                  </div>
                </div> */}

      {/* objectPrice */}
      {/* <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">objectPrice</label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <input
                        type="text"
                        className={`form-control form-control-lg form-control-solid `}
                        id="objectPrice"
                        name="objectPrice"
                        value={inputValue.objectPrice}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["objectPrice"]}
                    </span>
                  </div>
                </div> */}

      {/* loanAmmount */}
      {/* <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">loanAmmount</label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <input
                        type="text"
                        className={`form-control form-control-lg form-control-solid `}
                        id="loanAmmount"
                        name="loanAmmount"
                        value={inputValue.loanAmmount}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["loanAmmount"]}
                    </span>
                  </div>
                </div> */}

      {/* fixedInterestRate */}
      {/* <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">fixedInterestRate</label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <input
                        type="text"
                        className={`form-control form-control-lg form-control-solid `}
                        id="fixedInterestRate"
                        name="fixedInterestRate"
                        value={inputValue.fixedInterestRate}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["fixedInterestRate"]}
                    </span>
                  </div>
                </div>
                 */}
      {/* salutation */}
      {/* <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">salutation</label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <input
                        type="text"
                        className={`form-control form-control-lg form-control-solid `}
                        id="salutation"
                        name="salutation"
                        value={inputValue.salutation}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["salutation"]}
                    </span>
                  </div>
                </div> */}

      {/* fax */}
      {/* <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">Fax</label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <input
                        type="text"
                        className={`form-control form-control-lg form-control-solid `}
                        id="fax"
                        name="fax"
                        value={inputValue?.fax}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["fax"]}
                    </span>
                  </div>
                </div> */}

      {/* bussiness */}
      {/* <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">bussiness</label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <input
                        type="text"
                        className={`form-control form-control-lg form-control-solid `}
                        id="bussiness"
                        name="bussiness"
                        value={inputValue.bussiness}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["bussiness"]}
                    </span>
                  </div>
                </div> */}

      {/* Strasse */}

      {/* plz */}
      {/* <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">plz</label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <input
                        type="text"
                        className={`form-control form-control-lg form-control-solid `}
                        id="plz"
                        name="plz"
                        value={inputValue.plz}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["plz"]}
                    </span>
                  </div>
                </div> */}

      {/* ort */}
      {/* <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">Ort</label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <input
                        type="text"
                        className={`form-control form-control-lg form-control-solid `}
                        id="ort"
                        name="ort"
                        value={inputValue.ort}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["ort"]}
                    </span>
                  </div>
                </div> */}

      {/* professionalStatus */}
      {/* <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">professionalStatus</label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <input
                        type="text"
                        className={`form-control form-control-lg form-control-solid `}
                        id="professionalStatus"
                        name="professionalStatus"
                        value={inputValue.professionalStatus}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["professionalStatus"]}
                    </span>
                  </div>
                </div> */}

      {/* remarks */}
      {/* <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">remarks</label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <input
                        type="text"
                        className={`form-control form-control-lg form-control-solid `}
                        id="remarks"
                        name="remarks"
                        value={inputValue.remarks}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["remarks"]}
                    </span>
                  </div>
                </div> */}

      {/* <div className="d-flex align-items-center justify-content-center">
                  {loading ? (
                    <button className="btn btn-success mr-2">
                      {loading && <span className="mx-3 spinner spinner-white"></span>}
                    </button>
                  ) : (
                    <button
                      onClick={(e) => {
                       handleUpdate(e)
                      }}
                      className="btn btn-success mr-2"
                    >
                      <span> Edit </span>
                      {loading && <span className="mx-3 spinner spinner-white"></span>}
                    </button>
                  )}
                </div>
              </div>
            )}
          </List>
        </Dialog>
      ) : null} */}

      {isinfo ? (
        <Dialog
          fullScreen
          open={isinfo}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
          <List>
            {isinfo && (
              <>
                <div className="ml-40">
                  <div className="form cus-container">
                    {/* Name */}
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">
                        Name :
                      </label>
                      <div className="col-lg-9 col-xl-6 pt-3">
                        <div>
                          <span>{inputValue.name}</span>
                        </div>
                      </div>
                    </div>
                    {/* Email */}
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">
                        E-Mail :
                      </label>
                      <div className="col-xl-3 col-lg-3 pt-3">
                        <div>
                          <span>{inputValue?.email}</span>
                        </div>
                      </div>
                    </div>
                    {/* telephone */}
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">
                        Telefon :
                      </label>
                      <div className="col-xl-3 col-lg-3 pt-3">
                        <div>
                          <span>{inputValue?.telephone}</span>
                        </div>
                      </div>
                    </div>
                    {/* plan */}
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">
                        Plan :
                      </label>
                      <div className="col-xl-3 col-lg-3 pt-3">
                        <div>
                          <span>{inputValue?.plan}</span>
                        </div>
                      </div>
                    </div>
                    {/* object */}
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">
                        Objekt :
                      </label>
                      <div className="col-xl-3 col-lg-3 pt-3">
                        <div>
                          <span>{inputValue?.object}</span>
                        </div>
                      </div>
                    </div>
                    {/* objectPrice */}
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">
                        Objekt Preis :
                      </label>
                      <div className="col-xl-3 col-lg-3 pt-3">
                        <div>
                          <span>{inputValue?.objectPrice}</span>
                        </div>
                      </div>
                    </div>
                    {/* loanAmmount */}
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">
                        Darlehen Munmount :
                      </label>
                      <div className="col-xl-3 col-lg-3 pt-3">
                        <div>
                          <span>{inputValue?.loanAmmount}</span>
                        </div>
                      </div>
                    </div>
                    {/* use */}
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">
                        Verwenden :
                      </label>
                      <div className="col-xl-3 col-lg-3 pt-3">
                        <div>
                          <span>{inputValue?.use}</span>
                        </div>
                      </div>
                    </div>
                    {/* fax */}
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">
                        Fax :
                      </label>
                      <div className="col-xl-3 col-lg-3 pt-3">
                        <div>
                          <span>{inputValue?.fax}</span>
                        </div>
                      </div>
                    </div>
                    {/* fixedInterestRate */}
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">
                        Fester Zinssatz :
                      </label>
                      <div className="col-xl-3 col-lg-3 pt-3">
                        <div>
                          <span>{inputValue?.fixedInterestRate}</span>
                        </div>
                      </div>
                    </div>
                    {/* salutation */}
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">
                        Anrede :
                      </label>
                      <div className="col-xl-3 col-lg-3 pt-3">
                        <div>
                          <span>{inputValue?.salutation}</span>
                        </div>
                      </div>
                    </div>
                    {/* bussiness */}
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">
                        Geschäft :
                      </label>
                      <div className="col-xl-3 col-lg-3 pt-3">
                        <div>
                          <span>{inputValue?.bussiness}</span>
                        </div>
                      </div>
                    </div>
                    {/* plz */}
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">
                        PLZ :
                      </label>
                      <div className="col-xl-3 col-lg-3 pt-3">
                        <div>
                          <span>{inputValue?.plz}</span>
                        </div>
                      </div>
                    </div>
                    {/* ort */}
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">
                        ort :
                      </label>
                      <div className="col-xl-3 col-lg-3 pt-3">
                        <div>
                          <span>{inputValue?.ort}</span>
                        </div>
                      </div>
                    </div>
                    {/* Strasse */}
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">
                        Strasse :
                      </label>
                      <div className="col-xl-3 col-lg-3 pt-3">
                        <div>
                          <span>{inputValue?.Strasse}</span>
                        </div>
                      </div>
                    </div>
                    {/* remarks */}
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">
                        Bemerkungen :
                      </label>
                      <div className="col-xl-3 col-lg-3 pt-3">
                        <div>
                          <span>{inputValue?.remarks}</span>
                        </div>
                      </div>
                    </div>
                    {/* professionalStatus */}
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">
                        Fachfrau status :
                      </label>
                      <div className="col-xl-3 col-lg-3 pt-3">
                        <div>
                          <span>{inputValue?.professionalStatus}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </List>
        </Dialog>
      ) : null}
    </>
  );
};

export default Inquiry;
