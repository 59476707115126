import React, { useEffect, useState } from "react";
import { getUserInfo } from "../../../../utils/user.util";
import { NavLink } from "react-router-dom";
// import Shop from "../../../../_metronic/components/Shop/Shop";
// import Manufacture from "../../../../_metronic/components/Manufacture/Manufacture";
import { ApiGet } from "../../../../helpers/API/ApiData";
import { toast, ToastContainer } from "react-toastify";
import { useHistory } from "react-router-dom";
import CountUp from "react-countup";

export function MixedWidget1({ className }) {
  let userInfo = getUserInfo();
  const [title, setTitle] = useState();
  const [totalInquiry, setTotalInquiry] = useState();
  const [totalContact, setTotalContact] = useState();
  const [totalMedia, setTotalMedia] = useState();
  const [totalHomeWel, setTotalHomeWel] = useState();
  const [totalFetures, setTotalFetures] = useState();
  const [totalCustomer, setTotalCustomer] = useState();
  const [totalTeam, setTotalTeam] = useState();
  const [totalReport, setTotalReport] = useState();
  const [totalRequest, setTotalRequest] = useState();

  const history = useHistory();

  useEffect(() => {
    document.title = "Sayahi | Dashboard";
    setTitle("Sayahi | Dashboard");
  }, []);

  useEffect(() => {
    getAllInquiry();
    getAllContact();
    getAllMedia();
    getAllHomeWel();
    getAllFetures();
    getAllCustomer();
    getAllTeam();
    getAllReport();
    getAllRequest();
  }, []);

  const jumpOnInquiry = (e) => {
    history.push("/inquiry");
  };
  const jumpOnContact = (e) => {
    history.push("/contact");
  };
  const jumpOnMedia = (e) => {
    history.push("/socialmedia");
  };
  const jumpOnHomeWel = (e) => {
    history.push("/Homewelcomtext");
  };
  const jumpOnFetures = (e) => {
    history.push("/Homefeatures");
  };
  const jumpOnCustomer = (e) => {
    history.push("/Commercialcustomers");
  };
  const jumpOnTeam = (e) => {
    history.push("/Teaminfo");
  };
  const jumpOnReport = (e) => {
    history.push("/reportdamage");
  };
  const jumpOnRequest = (e) => {
    history.push("/reruestevbnumber");
  };

  const getAllInquiry = async () => {
    await ApiGet(`inquiry/getInquiry`).then((res) => {
      setTotalInquiry(res?.data?.payload?.count);
    });
  };

  const getAllContact = async () => {
    await ApiGet(`contact/getContact`).then((res) => {
      setTotalContact(res?.data?.payload?.count);
    });
  };

  const getAllMedia = async () => {
    await ApiGet(`socialMedia/getSocialMedia`).then((res) => {
      setTotalMedia(res?.data?.payload?.count);
    });
  };

  const getAllHomeWel = async () => {
    await ApiGet(`homeWelcomeText/getHomeWelcomeText`).then((res) => {
      setTotalHomeWel(res?.data?.payload?.count);
    });
  };

  const getAllFetures = async () => {
    await ApiGet(`homeFeatures/getHomeFeatures`).then((res) => {
      setTotalFetures(res?.data?.payload?.count);
    });
  };

  const getAllCustomer = async () => {
    await ApiGet(`commercialCustomers/getCommercialCustomers`).then((res) => {
      setTotalCustomer(res?.data?.payload?.count);
    });
  };

  const getAllTeam = async () => {
    await ApiGet(`teamInfo/getTeamInfo`).then((res) => {
      setTotalTeam(res?.data?.payload?.teamInfo.length);
    });
  };

  const getAllReport = async () => {
    await ApiGet(`reportDamage/getReportDamage`).then((res) => {
      setTotalReport(res?.data?.payload?.count);
    });
  };

  const getAllRequest = async () => {
    await ApiGet(`requestEvbNumber/getRequestEvbNumber`).then((res) => {
      setTotalRequest(res?.data?.payload?.count);
    });
  };

  return (
    <div className={`card card-custom bg-gray-100 ${className}`}>
      {/* <ToastContainer /> */}
      {/* Header */}
      <>
        <div
          className={`card card-custom`}
          style={{ backgroundColor: "#3F4255" }}
        >
          <div className="card-header border-0  py-1 px-1 m-5">
            <div className="card-body p-0 position-relative overflow-hidden">
              <div className="card-rounded-bottom" style={{ height: "25px" }}>
                <h4 className="font-weight-bolder text-white pt-2 pl-6">
                  Dashboard
                </h4>
              </div>

              <>
                <div className="card-spacer">
                  <div
                    className="carder-box"
                    style={{
                      display: "grid",
                      gap: "12px",
                      gridTemplateColumns: "repeat(4,1fr)",
                      padding: "20px",
                    }}
                  ></div>
                </div>
              </>

              <>
                <div className="card-spacer">
                  <div
                    className="carder-box"
                    style={{
                      display: "grid",
                      gap: "12px",
                      gridTemplateColumns: "repeat(5,2fr)",
                      padding: "20px",
                    }}
                  >
                    {/* inquiry */}

                    <div className="bg-light-warning p-6 rounded">
                      <div className="row">
                        <div className="col-12">
                          <div className="d-flex justify-content-between">
                            <div>
                              <h1
                                className="text-warning font-weight-bold"
                                style={{ fontSize: "40px" }}
                              >
                                {totalInquiry && (
                                  <CountUp
                                    end={totalInquiry}
                                    start={0}
                                    delay={1}
                                  />
                                )}
                              </h1>
                            </div>
                            <div style={{ display: "flex", marginTop: "6px" }}>
                              <span className="svg-icon menu-icon">
                                <i
                                  className="fa fa-info-circle"
                                  style={{ color: "#383839", fontSize: "25px" }}
                                  aria-hidden="true"
                                ></i>
                              </span>
                            </div>
                          </div>
                        </div>

                        <span
                          className="text-warning font-weight-bold font-size-h3 d-block my-2 ml-3"
                          style={{ cursor: "pointer" }}
                        >
                          Anfrage
                        </span>
                      </div>
                      <div
                        className="showMore"
                        onClick={(e) => jumpOnInquiry(e)}
                      >
                        <span style={{ cursor: "pointer" }}>
                          {" "}
                          Mehr anzeigen{" "}
                          <span
                            className="fa-solid fa-arrow-right-long ml-2"
                            style={{ cursor: "pointer" }}
                          ></span>
                        </span>
                      </div>
                    </div>

                    {/* report damage */}

                    <div className="bg-light-warning p-6 rounded">
                      <div className="row">
                        <div className="col-12">
                          <div className="d-flex justify-content-between">
                            <div>
                              <h1
                                className="text-warning font-weight-bold"
                                style={{ fontSize: "40px" }}
                              >
                                {totalReport && (
                                  <CountUp
                                    end={totalReport}
                                    start={0}
                                    delay={1}
                                  />
                                )}
                              </h1>
                            </div>
                            <span className="svg-icon menu-icon">
                              <i
                                className="fa fa-microchip"
                                style={{ color: "#383839", fontSize: "25px" }}
                                aria-hidden="true"
                              ></i>
                            </span>
                          </div>
                        </div>

                        <span
                          className="text-warning font-weight-bold font-size-h3 d-block my-2 ml-3"
                          style={{ cursor: "pointer" }}
                        >
                          Gemeldete Schäden
                        </span>
                      </div>
                      <div
                        className="showMore"
                        onClick={(e) => jumpOnReport(e)}
                      >
                        <span style={{ cursor: "pointer" }}>
                          {" "}
                          Mehr anzeigen{" "}
                          <span
                            className="fa-solid fa-arrow-right-long ml-2"
                            style={{ cursor: "pointer" }}
                          ></span>
                        </span>
                      </div>
                    </div>

                    {/* request evb no. */}

                    <div className="bg-light-warning p-6 rounded">
                      <div className="row">
                        <div className="col-12">
                          <div className="d-flex justify-content-between">
                            <div>
                              <h1
                                className="text-warning font-weight-bold"
                                style={{ fontSize: "40px" }}
                              >
                                {totalRequest && (
                                  <CountUp
                                    end={totalRequest}
                                    start={0}
                                    delay={1}
                                  />
                                )}
                              </h1>
                            </div>
                            <span className="svg-icon menu-icon">
                              <i
                                className="fa fa-etsy"
                                style={{ color: "#383839", fontSize: "25px" }}
                                aria-hidden="true"
                              ></i>
                            </span>
                          </div>
                        </div>

                        <span
                          className="text-warning font-weight-bold font-size-h3 d-block my-2 ml-3"
                          style={{ cursor: "pointer" }}
                        >
                          EVB - Nummer anfordern
                        </span>
                      </div>
                      <div
                        className="showMore"
                        onClick={(e) => jumpOnRequest(e)}
                      >
                        <span style={{ cursor: "pointer" }}>
                          {" "}
                          Mehr anzeigen{" "}
                          <span
                            className="fa-solid fa-arrow-right-long ml-2"
                            style={{ cursor: "pointer" }}
                          ></span>
                        </span>
                      </div>
                    </div>

                    {/* contact */}
                    {/* <div className="bg-light-warning p-6 rounded">
                      <div className="row">
                        <div className="col-12">
                          <div className="d-flex justify-content-between">
                            <div>
                              <h1
                                className="text-warning font-weight-bold"
                                style={{ fontSize: "40px" }}
                              >
                                {totalContact && (
                                  <CountUp
                                    end={totalContact}
                                    start={0}
                                    delay={1}
                                  />
                                )}
                              </h1>
                            </div>
                            <span className="svg-icon menu-icon">
                                <i
                                  className="fa-solid fa-building"
                                  style={{ color: "#383839", fontSize: "25px" }}
                                  aria-hidden="true"
                                ></i>
                              </span>
                          </div>
                        </div>
                        <span
                          className="text-warning font-weight-bold font-size-h3 d-block my-2 ml-3"
                          style={{ cursor: "pointer" }}
                        >
                         Kontakt
                        </span>
                      </div>
                      <div
                        className="showMore d-flex justify-content-center"
                        onClick={(e) => jumpOnContact(e)}
                      >
                        <span
                          className=" d-flex align-items-center"
                        >
                          {" "}
                          Mehr anzeigen{" "}<span className="fa-solid fa-arrow-right-long ml-2"></span>
                        </span>
                      </div>
                    </div> */}

                    <div className="bg-light-warning p-6 rounded">
                      <div className="row">
                        <div className="col-12">
                          <div className="d-flex justify-content-between">
                            <div>
                              <h1
                                className="text-warning font-weight-bold"
                                style={{ fontSize: "40px" }}
                              >
                                {totalContact && (
                                  <CountUp
                                    end={totalContact}
                                    start={0}
                                    delay={1}
                                  />
                                )}
                              </h1>
                            </div>
                            <span className="svg-icon menu-icon">
                              <i
                                className="fa-solid fa-building"
                                style={{ color: "#383839", fontSize: "25px" }}
                                aria-hidden="true"
                              ></i>
                            </span>
                          </div>
                        </div>
                        <span
                          className="text-warning font-weight-bold font-size-h3 d-block my-2 ml-3"
                          style={{ cursor: "pointer" }}
                        >
                          Kontakt
                        </span>
                      </div>
                      <div
                        className="showMore"
                        onClick={(e) => jumpOnContact(e)}
                      >
                        <span style={{ cursor: "pointer" }}>
                          {" "}
                          Mehr anzeigen{" "}
                          <span
                            className="fa-solid fa-arrow-right-long ml-2"
                            style={{ cursor: "pointer" }}
                          ></span>
                        </span>
                      </div>
                    </div>

                    {/* Socia Media */}

                    <div className="bg-light-warning p-6 rounded">
                      <div className="row">
                        <div className="col-12">
                          <div className="d-flex justify-content-between">
                            <div>
                              <h1
                                className="text-warning font-weight-bold"
                                style={{ fontSize: "40px" }}
                              >
                                {totalMedia && (
                                  <CountUp
                                    end={totalMedia}
                                    start={0}
                                    delay={1}
                                  />
                                )}
                              </h1>
                            </div>
                            <span className="svg-icon menu-icon">
                              <i
                                className="fa fa-flickr"
                                style={{ color: "#383839", fontSize: "25px" }}
                                aria-hidden="true"
                              ></i>
                            </span>
                          </div>
                        </div>

                        <span
                          className="text-warning font-weight-bold font-size-h3 d-block my-2 ml-3"
                          style={{ cursor: "pointer" }}
                        >
                          Social Media
                        </span>
                      </div>
                      <div className="showMore" onClick={(e) => jumpOnMedia(e)}>
                        <span style={{ cursor: "pointer" }}>
                          {" "}
                          Mehr anzeigen{" "}
                          <span
                            className="fa-solid fa-arrow-right-long ml-2"
                            style={{ cursor: "pointer" }}
                          ></span>
                        </span>
                      </div>
                    </div>

                    {/* home welcome text */}

                    <div className="bg-light-warning p-6 rounded">
                      <div className="row">
                        <div className="col-12">
                          <div className="d-flex justify-content-between">
                            <div>
                              <h1
                                className="text-warning font-weight-bold"
                                style={{ fontSize: "40px" }}
                              >
                                {totalHomeWel && (
                                  <CountUp
                                    end={totalHomeWel}
                                    start={0}
                                    delay={1}
                                  />
                                )}
                              </h1>
                            </div>
                            <span className="svg-icon menu-icon">
                              <i
                                className="fa fa-home"
                                style={{ color: "#383839", fontSize: "25px" }}
                                aria-hidden="true"
                              ></i>
                            </span>
                          </div>
                        </div>

                        <span
                          className="text-warning font-weight-bold font-size-h3 d-block my-2 ml-3"
                          style={{ cursor: "pointer" }}
                        >
                          Home Screen
                        </span>
                      </div>
                      <div
                        className="showMore"
                        onClick={(e) => jumpOnHomeWel(e)}
                      >
                        <span style={{ cursor: "pointer" }}>
                          {" "}
                          Mehr anzeigen{" "}
                          <span
                            className="fa-solid fa-arrow-right-long ml-2"
                            style={{ cursor: "pointer" }}
                          ></span>
                        </span>
                      </div>
                    </div>

                    {/* home fetures */}

                    <div className="bg-light-warning p-6 rounded">
                      <div className="row">
                        <div className="col-12">
                          <div className="d-flex justify-content-between">
                            <div>
                              <h1
                                className="text-warning font-weight-bold"
                                style={{ fontSize: "40px" }}
                              >
                                {totalFetures && (
                                  <CountUp
                                    end={totalFetures}
                                    start={0}
                                    delay={1}
                                  />
                                )}
                              </h1>
                            </div>
                            <span className="svg-icon menu-icon">
                              <i
                                className="fa fa-ravelry"
                                style={{ color: "#383839", fontSize: "25px" }}
                                aria-hidden="true"
                              ></i>
                            </span>
                          </div>
                        </div>

                        <span
                          className="text-warning font-weight-bold font-size-h3 d-block my-2 ml-3"
                          style={{ cursor: "pointer" }}
                        >
                          Home Screen Texte
                        </span>
                      </div>
                      <div
                        className="showMore"
                        onClick={(e) => jumpOnFetures(e)}
                      >
                        <span style={{ cursor: "pointer" }}>
                          {" "}
                          Mehr anzeigen{" "}
                          <span
                            className="fa-solid fa-arrow-right-long ml-2"
                            style={{ cursor: "pointer" }}
                          ></span>
                        </span>
                      </div>
                    </div>

                    {/* comercial customer */}

                    <div className="bg-light-warning p-6 rounded">
                      <div className="row">
                        <div className="col-12">
                          <div className="d-flex justify-content-between">
                            <div>
                              <h1
                                className="text-warning font-weight-bold"
                                style={{ fontSize: "40px" }}
                              >
                                {totalCustomer && (
                                  <CountUp
                                    end={totalCustomer}
                                    start={0}
                                    delay={1}
                                  />
                                )}
                              </h1>
                            </div>
                            <span className="svg-icon menu-icon">
                              <i
                                className="fa fa-align-justify"
                                style={{ color: "#383839", fontSize: "25px" }}
                                aria-hidden="true"
                              ></i>
                            </span>
                          </div>
                        </div>

                        <span
                          className="text-warning font-weight-bold font-size-h3 d-block my-2 ml-3"
                          style={{ cursor: "pointer" }}
                        >
                          Referenzen
                        </span>
                      </div>
                      <div
                        className="showMore"
                        onClick={(e) => jumpOnCustomer(e)}
                      >
                        <span style={{ cursor: "pointer" }}>
                          {" "}
                          Mehr anzeigen{" "}
                          <span
                            className="fa-solid fa-arrow-right-long ml-2"
                            style={{ cursor: "pointer" }}
                          ></span>
                        </span>
                      </div>
                    </div>

                    {/* team info */}

                    <div className="bg-light-warning p-6 rounded">
                      <div className="row">
                        <div className="col-12">
                          <div className="d-flex justify-content-between">
                            <div>
                              <h1
                                className="text-warning font-weight-bold"
                                style={{ fontSize: "40px" }}
                              >
                                {totalTeam && (
                                  <CountUp
                                    end={totalTeam}
                                    start={0}
                                    delay={1}
                                  />
                                )}
                              </h1>
                            </div>
                            <span className="svg-icon menu-icon">
                              <i
                                className="fa fa-question"
                                style={{ color: "#383839", fontSize: "25px" }}
                                aria-hidden="true"
                              ></i>
                            </span>
                          </div>
                        </div>

                        <span
                          className="text-warning font-weight-bold font-size-h3 d-block my-2 ml-3"
                          style={{ cursor: "pointer" }}
                        >
                          Team
                        </span>
                      </div>
                      <div className="showMore" onClick={(e) => jumpOnTeam(e)}>
                        <span style={{ cursor: "pointer" }}>
                          {" "}
                          Mehr anzeigen{" "}
                          <span
                            className="fa-solid fa-arrow-right-long ml-2"
                            style={{ cursor: "pointer" }}
                          ></span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}
