import React, { useEffect, useState } from "react";
import { title } from "process";
import { Button, Modal } from "react-bootstrap";
import DataTable, { defaultThemes } from "react-data-table-component";
import { toast, ToastContainer } from "react-toastify";
import { ApiDelete, ApiGet, ApiPost, ApiPut } from "../../../helpers/API/ApiData";
import { Dialog, IconButton, List, Slide, Toolbar, Tooltip } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import { ThreeCircles } from "react-loader-spinner";
import InfoIcon from "@material-ui/icons/Info";


const Transition: any = React.forwardRef(function Transition(props: any, ref: any) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Reportdamage = () => {
  const [isLoaderVisible, setIsLoaderVisible] = useState<boolean>(false);
  const [isEditApi, setIsEditApi] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isAdd, setIsAdd] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [countPerPage, setCountPerPage] = useState<number>(10);
  const [count, setCount] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [getReportDamage, setGetreportdamage] = useState<any>([]);
  const [idForUpdate, setIdForUpdate] = useState<string>("");
  const [idForDelete, setIdForDelete] = useState<string>("");
  const [inputValue, setInputValue] = useState<any>({});
  const [filterData, setFilterData] = useState<any>();
  const [errors, setErrors] = useState<any>({});
  const [idForinfo, setIdForinfo] = useState<any>({});
  const [isinfo, setIsinfo] = useState<any>(false);
  const [idFordeleteaboutapp, setIdForDeleteAboutApp] = useState<any>();
  const [uploadFile,setUploadFile]=useState<any>()
  const [ editImg,setEditImg]=useState<any>()
  const [serachbox, setSerachbox] = useState<any>("");

  useEffect(() => {
    title === "Sayahi | Dashboard"
      ? (document.title = title)
      : (document.title = "Sayahi | Report Damage");
  }, []);

  useEffect(() => {
    getAllreportdamage();
  }, [page, countPerPage]);

  const SearchfilterData = getReportDamage && getReportDamage?.filter((el :any, index :any) => {
    if (serachbox === "") {
        return el;
    } else if (serachbox !== "") {
        let fullName = `${el.firstName} ${el.surName}`;
        let res = fullName.toLowerCase();
        return res.match(serachbox.toLowerCase());
    }
});

const handleSearch = (e: any) => {
  var value = e.target.value;
  setSerachbox(value)
};

  const handleOnChangeImg = (e: any) => {
    const { name } = e.target;
    setUploadFile(e.target.files[0]);
    // setErrors({ ...errors, [name]: "" });
  };
  const handleClose = () => {
    setInputValue({});
    setErrors({});
    setIsAdd(false);
    setIsEditApi(false);
    setIsinfo(false);

  };
  const handleOnChange = (e: any) => {
    const { name, value } = e.target;
    setInputValue({ ...inputValue, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };
  const validateFormUpdate = () => {
    let formIsValid = true;
    let errors: any = {};
    if (inputValue && !inputValue.firstName) {
      formIsValid = false;
      errors["firstName"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (inputValue && !inputValue.surName) {
      formIsValid = false;
      errors["surName"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (inputValue && !inputValue.email) {
      formIsValid = false;
      errors["email"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (inputValue && !inputValue.zipCode) {
      formIsValid = false;
      errors["zipCode"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (inputValue && !inputValue.telephone) {
      formIsValid = false;
      errors["telephone"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (inputValue && !inputValue.strasse) {
      formIsValid = false;
      errors["strasse"] = "Bitte überprüfen Sie ihre Eingabe*";
    }

    if (inputValue && !inputValue.insuranceNumber) {
      formIsValid = false;
      errors["insuranceNumber"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (inputValue && !inputValue.herr) {
      formIsValid = false;
      errors["herr"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (inputValue && !inputValue.fax) {
      formIsValid = false;
      errors["fax"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (inputValue && !inputValue.description) {
      formIsValid = false;
      errors["description"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (inputValue && !inputValue.damageArt) {
      formIsValid = false;
      errors["damageArt"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    setErrors(errors);
    return formIsValid;
  };
  const handleCloseDelete = () => {
    setShow(false);
  };
  const getAllreportdamage = async () => {
    setIsLoaderVisible(true);
    await ApiGet(`reportDamage/getReportDamage`)
      .then((res: any) => {
       
        setIsLoaderVisible(false);
        setGetreportdamage(res?.data?.payload?.reportDamage);
        // setFilterData(res?.data?.data);
        // setCount(res?.data?.data?.length);
        // toast.success(res?.data?.message)
      })
      .catch((err) => { });
  };
  const handleUpdate = (e: any) => {
    e.preventDefault();
   if (validateFormUpdate()) {
    setLoading(true);
    let formData = new FormData();
    formData.append("firstName", inputValue?.firstName);
    formData.append("surName", inputValue?.surName);
    formData.append("email", inputValue?.email);
    formData.append("zipCode", inputValue?.zipCode);
    formData.append("telephone", inputValue?.telephone);
    formData.append("strasse", inputValue?.strasse);
    formData.append("ort", inputValue?.ort);
    formData.append("insuranceNumber", inputValue?.insuranceNumber);
    formData.append("herr", inputValue?.herr);
    formData.append("fax", inputValue?.fax);
    formData.append("description", inputValue?.description);
    formData.append("damageArt", inputValue?.damageArt);
    formData.append("picturesOfDamage", uploadFile ? uploadFile : editImg);


    ApiPut(`reportDamage/updateReportDamage/${idForUpdate}`, formData)
      .then((res: any) => {
        if (res?.status == 200) {
          setInputValue({});
          setIsAdd(false);
          setIsEditApi(false);
          toast.success(res?.data?.message);
          getAllreportdamage();
          setLoading(false);
        } else {
          toast.error(res?.data?.message);
          setLoading(false);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        setLoading(false);
      });
     }
  };

  const handleDelete = () => {
    setLoading(true);
    let formData = new FormData();
    formData.append("isActive", "false");
    ApiPut(`reportDamage/updateReportDamage/${idFordeleteaboutapp?._id}`, formData)
      .then((res: any) => {
       
        if (res?.status == 200) {
          setShow(false);
          toast.success("Deleted Successfully");
          getAllreportdamage();
          setPage(1);
          setCount(0);
          setCountPerPage(countPerPage);
        } else {
          toast.error(res?.data?.message);
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }

  
const columns: any = [
    {
      name: "Nr.",
      cell: (row: any, index: any) => (page - 1) * countPerPage + (index + 1),
      width: "65px",
    },
    {
      name: "Vorname",
      selector: (row: any) => row?.firstName,
      sortable: true,
    },
    {
      name: "Nachname",
      selector: (row: any) => row?.surName,
      sortable: true,
    },
    {
      name: "E-Mail",
      selector: (row: any) => row?.email,
      sortable: true,
    },
    // {
    //   name: "Postleitzahl",
    //   selector: (row: any) => row?.zipCode,
    //   sortable: true,
    // },
    {
      name: "Telefon",
      selector: (row: any) => row?.telephone,
      sortable: true,
    },
    // {
    //   name: "strasse",
    //   selector: (row: any) => row?.strasse,
    //   sortable: true,
    // },
    // {
    //   name: "ort",
    //   selector: (row: any) => row?.ort,
    //   sortable: true,
    // },
    // {
    //   name: "Versicherungsnummer",
    //   selector: (row: any) => row?.insuranceNumber,
    //   sortable: true,
    // },
    // {
    //   name: "Herrin",
    //   selector: (row: any) => row?.herr,
    //   sortable: true,
    // },
    // {
    //   name: "Fax",
    //   selector: (row: any) => row?.fax,
    //   sortable: true,
    // },
    // {
    //   name: "Objekt",
    //   selector: (row: any) => row?.fax,
    //   sortable: true,
    // },
    // {
    //   name: "Bezeichnung",
    //   selector: (row: any) => row?.description,
    //   sortable: true,
    // },
    // {
    //   name: "Damageart",
    //   selector: (row: any) => row?.damageArt,
    //   sortable: true,
    // },
    // {
    //   name: "picturesOfDamage",
    //   cell: (row: any) => {
    //     return (
    //       <>
    //         <div className="mt-4 mb-4 p-1 border border-dark rounded">
    //           <img src={row?.picturesOfDamage?.[0]} width={100} height={50} />
    //         </div>
    //       </>
    //     );
    //   },
    //   sortable: true,
    // },
    {
      name: "Aktionen",
      cell: (row: any) => {
        return (
          <>
            <div className="d-flex justify-content-between">
              {/* <div
                className="cursor-pointer pl-2"
                onClick={() => {
                  setIsAdd(true);
                  setIsEditApi(true);
                  console.log("row",row)
                  
                  setInputValue({
                    firstName: row?.firstName,
                    surName: row?.surName,
                    email: row?.email,
                    zipCode: row?.zipCode,
                    telephone: row?.telephone,
                    strasse: row?.strasse,
                    ort: row?.ort,
                    insuranceNumber : row?.insuranceNumber,
                    herr : row?.herr,
                    fax :row?.fax,
                    description :row?.description,
                    damageArt : row?.damageArt,
                    picturesOfDamage:setEditImg(row?.picturesOfDamage)
                  });
                  
                  setIdForUpdate(row?._id);
                }}
              >
                <Tooltip title="Edit" arrow>
                  <CreateIcon />
                </Tooltip>
              </div> */}
              <div>
                <div
                  className="cursor-pointer pl-2"
                  onClick={() => {
                    setIsinfo(true);
                    setInputValue({
                      firstName: row?.firstName,
                      surName: row?.surName,
                      email: row?.email,
                      zipCode: row?.zipCode,
                      telephone: row?.telephone,
                      strasse: row?.strasse,
                      ort: row?.ort,
                      insuranceNumber: row?.insuranceNumber,
                      herr: row?.herr,
                      fax: row?.fax,
                      description: row?.description,
                      damageArt: row?.damageArt,
                      picturesOfDamage: setEditImg(row?.picturesOfDamage)
                    });
                  }}
                >
                  <Tooltip title="Information" arrow>
                    <InfoIcon />
                  </Tooltip>
                </div>
              </div>
              <div
                className="cursor-pointer"
                onClick={() => {
                  setShow(true);
                  setIdForDeleteAboutApp(row);
                }}
              >
                <Tooltip title="Löschen" arrow>
                  <DeleteIcon />
                </Tooltip>
              </div>
            </div>
            <div
              className="cursor-pointer"
              onClick={() => {
                setShow(true);
                setIdForDelete(row?._id);
              }}
            >
            </div>
          </>
        );
      },
    },
  ];
console.log("editImg",editImg)

  

  // * Table Style
  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: defaultThemes.default.divider.default,
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };

  return (
    <>
      <div className="card p-1">
        {document.title === "Sayahi | Report Damage" && <ToastContainer />}
        <div className="p-2 mb-2">
          <div className="row mb-4 pr-3">
            <div className="col d-flex justify-content-between">
              <h2 className="pl-3 pt-2">Gemeldete Schäden</h2>
            </div>
            <div className="col">
              <div>
                <input
                  type="text"
                  className={`form-control form-control-solid `}
                  name="search"
                  // value={search}
                  placeholder="Gemeldete Schäden suchen…"
                  onChange={(e) => handleSearch(e)}
                />
              </div>
            </div>
            {/* <div className="cus-medium-button-style button-height">
              <button
                onClick={() => {
                  setIsAdd(true);
                }}
                className="btn btn-success mr-2"
              >
                Add Shop
              </button>
            </div> */}
          </div>

          {/* delete model */}
          <Modal show={show} onHide={handleCloseDelete}>
            <Modal.Header closeButton>
              <Modal.Title className="text-danger">Alert!</Modal.Title>
            </Modal.Header>
            <Modal.Body>Sind Sie sicher, dass Sie diesen Berichtsschaden löschen möchten?</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseDelete}>
               Abbrechen
              </Button>
              <Button
                variant="danger"
                onClick={() => {
                  handleDelete();
                }}
              >
               Löschen
              </Button>
            </Modal.Footer>
          </Modal>
          {/* end delete model */}

          <DataTable
            columns={columns}
            data={SearchfilterData}
            customStyles={customStyles}
            style={{
              marginTop: "-3rem",
            }}
            progressPending={isLoaderVisible}
            progressComponent={<ThreeCircles color="#334D52" height={30} width={30} />}
            highlightOnHover
            pagination
              // paginationServer
              // paginationTotalRows={count}
              paginationPerPage={countPerPage}
              paginationRowsPerPageOptions={[10, 20, 25, 50, 100]}
              paginationDefaultPage={page}
              onChangePage={(page) => {
                setPage(page);
              }}
              onChangeRowsPerPage={(rowPerPage) => {
                setCountPerPage(rowPerPage);
              }}
            // pagination
            // paginationServer
            // paginationTotalRows={count}
            // paginationPerPage={countPerPage}
            // paginationRowsPerPageOptions={[10, 20, 25, 50, 100]}
            // paginationDefaultPage={page}
            // onChangePage={(page) => {
            //   setPage(page);
            // }}
            // onChangeRowsPerPage={(rowPerPage) => {
            //   setCountPerPage(rowPerPage);
            // }}
          />
        </div>
      </div>

      {/* {isAdd ? (
        <Dialog fullScreen open={isAdd} onClose={handleClose} TransitionComponent={Transition}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
          <List>
            {isAdd === true && (
              <div className="form ml-30 "> */}
      {/* firstName */}
      {/* <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">firstName</label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <input
                        type="text"
                        className={`form-control form-control-lg form-control-solid `}
                        id="firstName"
                        name="firstName"
                        value={inputValue?.firstName}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["firstName"]}
                    </span>
                  </div>
                </div> */}
      {/* surName */}
      {/* <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">surName</label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <input
                        type="text"
                        className={`form-control form-control-lg form-control-solid `}
                        id="surName"
                        name="surName"
                        value={inputValue?.surName}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["surName"]}
                    </span>
                  </div>
                </div> */}
      {/* email */}
      {/* <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">email</label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <input
                        type="email"
                        className={`form-control form-control-lg form-control-solid `}
                        id="email"
                        name="email"
                        value={inputValue.email}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["email"]}
                    </span>
                  </div>
                </div> */}
      {/* zipCode */}
      {/* <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">zipCode</label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <input
                        type="text"
                        className={`form-control form-control-lg form-control-solid `}
                        id="zipCode"
                        name="zipCode"
                        value={inputValue.zipCode}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["zipCode"]}
                    </span>
                  </div>
                </div> */}
      {/* telephone */}
      {/* <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">telephone</label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <input
                        type="text"
                        className={`form-control form-control-lg form-control-solid `}
                        id="telephone"
                        name="telephone"
                        value={inputValue.telephone}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["telephone"]}
                    </span>
                  </div>
                </div> */}
      {/* strasse */}
      {/* <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">strasse</label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <input
                        type="text"
                        className={`form-control form-control-lg form-control-solid `}
                        id="strasse"
                        name="strasse"
                        value={inputValue.strasse}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["strasse"]}
                    </span>
                  </div>
                </div> */}
      {/* ort */}
      {/* <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">ort</label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <input
                        type="text"
                        className={`form-control form-control-lg form-control-solid `}
                        id="ort"
                        name="ort"
                        value={inputValue.ort}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["ort"]}
                    </span>
                  </div>
                </div> */}
      {/* insuranceNumber */}
      {/* <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">insuranceNumber</label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <input
                        type="text"
                        className={`form-control form-control-lg form-control-solid `}
                        id="insuranceNumber"
                        name="insuranceNumber"
                        value={inputValue.insuranceNumber}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["insuranceNumber"]}
                    </span>
                  </div>
                </div> */}
      {/* herr */}
      {/* <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">herr</label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <input
                        type="text"
                        className={`form-control form-control-lg form-control-solid `}
                        id="herr"
                        name="herr"
                        value={inputValue.herr}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["herr"]}
                    </span>
                  </div>
                </div> */}
      {/* fax */}
      {/* <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">fax</label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <input
                        type="text"
                        className={`form-control form-control-lg form-control-solid `}
                        id="fax"
                        name="fax"
                        value={inputValue.fax}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["fax"]}
                    </span>
                  </div>
                </div> */}
      {/* description */}
      {/* <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">description</label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <input
                        type="text"
                        className={`form-control form-control-lg form-control-solid `}
                        id="description"
                        name="description"
                        value={inputValue.description}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["description"]}
                    </span>
                  </div>
                </div> */}
      {/* DamageArt */}
      {/* <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">DamageArt</label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <input
                        type="text"
                        className={`form-control form-control-lg form-control-solid `}
                        id="damageArt"
                        name="damageArt"
                        value={inputValue.damageArt}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["damageArt"]}
                    </span>
                  </div>
                </div> */}

      {/* <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">picturesOfDamage</label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <input
                        type="file"
                        className={`form-control form-control-lg form-control-solid `}
                        name="picturesOfDamage"
                        onChange={(e:any) => 
                          handleOnChangeImg(e)
                        }
                        accept="image/*"
                        required
                      />
                    </div>
                        {uploadFile ? (<>
                        <img src={URL.createObjectURL(uploadFile)} style={{height : "145px" , width :"200px"}}/>
                        </>):(<>
                        <img src={editImg} style={{height : "145px" , width :"200px"}}/>
                        </>)}
                                     
                  </div>
                </div>

                <div className="d-flex align-items-center justify-content-center">
                  {loading ? (
                    <button className="btn btn-success mr-2">
                      <span>{isEditApi ? "Edit" : "Add"}<li></li></span>
                      {loading && <span className="mx-3 spinner spinner-white"></span>}
                    </button>
                  ) : (
                    <button
                      onClick={(e) => {
                         handleUpdate(e);
                      }}
                      className="btn btn-success mr-2"
                    >
                      <span>edit</span>
                      {loading && <span className="mx-3 spinner spinner-white"></span>}
                    </button>
                  )}
                </div>
              </div>
            )}
          </List>
        </Dialog>
      ) : null} */}


      {isinfo ? (
        <Dialog fullScreen open={isinfo} onClose={handleClose} TransitionComponent={Transition}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
          <List>
            {isinfo && (
              <>

                <div className="ml-40">
                  <div className="form cus-container" >

                    {/* fName */}
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">
                      Vorname :
                      </label>
                      <div className="col-lg-9 col-xl-6 pt-3">
                        <div>
                          <span>{inputValue.firstName}</span>
                        </div>
                      </div>
                    </div>

                    {/* surname */}
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">
                      Nachname :
                      </label>
                      <div className="col-xl-3 col-lg-3 pt-3">
                        <div>
                          <span>{inputValue?.surName}</span>
                        </div>
                      </div>
                    </div>

                    {/* Email */}
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">
                      E-Mail :
                      </label>
                      <div className="col-xl-3 col-lg-3 pt-3">
                        <div>
                          <span>{inputValue?.email}</span>
                        </div>
                      </div>
                    </div>

                    {/* fax */}
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">
                      Fax :
                      </label>
                      <div className="col-xl-3 col-lg-3 pt-3">
                        <div>
                          <span>{inputValue?.fax}</span>
                        </div>
                      </div>
                    </div>

                    {/* ort */}
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">
                      Ort :
                      </label>
                      <div className="col-xl-3 col-lg-3 pt-3">
                        <div>
                          <span>{inputValue?.ort}</span>
                        </div>
                      </div>
                    </div>

                    {/* Strasse */}
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">
                      Straße :
                      </label>
                      <div className="col-xl-3 col-lg-3 pt-3">
                        <div>
                          <span>{inputValue?.strasse}</span>
                        </div>
                      </div>
                    </div>

                    {/* ZipCode */}
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">
                        Postleitzahl :
                      </label>
                      <div className="col-xl-3 col-lg-3 pt-3">
                        <div>
                          <span>{inputValue?.zipCode}</span>
                        </div>
                      </div>
                    </div>

                    {/* telephone */}
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">
                        Telefon :
                      </label>
                      <div className="col-xl-3 col-lg-3 pt-3">
                        <div>
                          <span>{inputValue?.telephone}</span>
                        </div>
                      </div>
                    </div>

                    {/* insuranceNumber */}
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">
                        Versicherungsnummer :
                      </label>
                      <div className="col-xl-3 col-lg-3 pt-3">
                        <div>
                          <span>{inputValue?.insuranceNumber}</span>
                        </div>
                      </div>
                    </div>

                    {/* herr */}
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">
                      Anrede :
                      </label>
                      <div className="col-xl-3 col-lg-3 pt-3">
                        <div>
                          <span>{inputValue?.herr}</span>
                        </div>
                      </div>
                    </div>

                    {/* description */}
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">
                        Beschreibung :
                      </label>
                      <div className="col-xl-3 col-lg-3 pt-3">
                        <div>
                          <span>{inputValue?.description}</span>
                        </div>
                      </div>
                    </div>

                    {/* damageArt */}
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">
                      Schadenart :
                      </label>
                      <div className="col-xl-3 col-lg-3 pt-3">
                        <div>
                          <span>{inputValue?.damageArt}</span>
                        </div>
                      </div>
                    </div>


                    {/* pictures */}

                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">
                      Bilder vom Schaden :
                      </label>
                      <div className="col-xl-3 col-lg-3 pt-3">
                        <div>
                          <span>  <div>

                            <img
                              style={{
                                height: "200px",
                                width: "250px",
                                objectFit: "contain",
                              }}
                              src={editImg}
                              alt=""
                            />

                          </div></span>
                        </div>
                      </div>
                    </div>


                  </div>

                </div>
              </>


            )}
          </List>
        </Dialog>
      ) : null}

    </>
  );
};

export default Reportdamage;



