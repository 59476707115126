import React, { useEffect, useState } from "react";
import { title } from "process";
import { Button, Modal } from "react-bootstrap";
import DataTable, { defaultThemes } from "react-data-table-component";
import { toast, ToastContainer } from "react-toastify";
import {
  ApiDelete,
  ApiGet,
  ApiPost,
  ApiPut,
} from "../../../helpers/API/ApiData";
import {
  Dialog,
  IconButton,
  List,
  Slide,
  Toolbar,
  Tooltip,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import { ThreeCircles } from "react-loader-spinner";
import InfoIcon from "@material-ui/icons/Info";

const Transition: any = React.forwardRef(function Transition(
  props: any,
  ref: any
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Commercialcustomers = () => {
  const [isLoaderVisible, setIsLoaderVisible] = useState<boolean>(false);
  const [isDeleteApi, setIsDeleteApi] = useState<boolean>(false);
  const [isEditApi, setIsEditApi] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isinfo, setIsinfo] = useState<boolean>(false);
  const [isAdd, setIsAdd] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [countPerPage, setCountPerPage] = useState<number>(10);
  const [count, setCount] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [getcommercialcustomers, setGetcommercialcustomers] = useState<any>();
  const [idForUpdate, setIdForUpdate] = useState<string>("");
  const [idForDelete, setIdForDelete] = useState<string>("");
  const [inputValue, setInputValue] = useState<any>({});
  // const [filterData, setFilterData] = useState<any>();
  const [errors, setErrors] = useState<any>({});
  const [idForinfo, setIdForinfo] = useState<any>();
  const [serachbox, setSerachbox] = useState<any>("");
  const [Getcustomers, setGetcustomers] = useState<any>([]);

  console.log("inputValueinputValue", inputValue);

  useEffect(() => {
    title === "Sayahi | Dashboard"
      ? (document.title = title)
      : (document.title = "SAYAHI | Commercial Customer");
  }, []);

  useEffect(() => {
    getAllcommercialcustomer();
  }, [page, countPerPage]);

  const handleOnChange = (e: any) => {
    const { name, value } = e.target;
    setInputValue({ ...inputValue, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };
  const handleOnChangeImg = (e: any) => {
    const { name } = e.target;
    setGetcommercialcustomers({
      ...getcommercialcustomers,
      [name]: e.target.files[0],
    });
    setErrors({ ...errors, [name]: "" });
  };
  const handleClose = () => {
    setInputValue({});
    setGetcommercialcustomers({});
    setErrors({});
    setIsAdd(false);
    setIsEditApi(false);
    setIsinfo(false);
    getAllcommercialcustomer();
    setIsDeleteApi(false);
  };

  const validateFormUpdate = () => {
    let formIsValid = true;
    let errors: any = {};
    if (!inputValue?.name || inputValue?.name.trim() === "") {
      formIsValid = false;
      errors["name"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (!inputValue?.description || inputValue?.description.trim() === "") {
      formIsValid = false;
      errors["description"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (!inputValue?.link || inputValue?.link.trim() === "") {
      formIsValid = false;
      errors["link"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (!getcommercialcustomers?.logo) {
      formIsValid = false;
      errors["logo"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    setErrors(errors);
    return formIsValid;
  };

  // const validateFormForAdd = () => {
  //   let formIsValid = true;
  //   let errors: any = {};

  //   if (inputValue && !inputValue.title) {
  //     formIsValid = false;
  //     errors["name"] = "*Please upload name!";
  //   }
  //   if (inputValue && !inputValue.description) {
  //       formIsValid = false;
  //       errors["description"] = "*Please upload description!";
  //     }
  //     if (getcommercialcustomers && !getcommercialcustomers.logo) {
  //       formIsValid = false;
  //       errors["logo"] = "*Please enter logo!";
  //     }

  //   setErrors(errors);
  //   return formIsValid;
  // };
  const getAllcommercialcustomer = async () => {
    setIsLoaderVisible(true);
    await ApiGet(`commercialCustomers/getCommercialCustomers`)
      .then((res: any) => {
        console.log("res", res);
        setIsLoaderVisible(false);
        setGetcommercialcustomers(res?.data?.payload?.commercialCustomers);
        setGetcustomers(res?.data?.payload?.commercialCustomers);

        // setFilterData(res?.data?.data);
        // setCount(res?.data?.data?.length);
        // toast.success(res?.data?.message)
      })
      .catch((err: any) => {});
  };
  const handleAdd = (e: any) => {
    e.preventDefault();
    if (validateFormUpdate()) {
      setLoading(true);
      let formData = new FormData();
      formData.append("name", inputValue?.name.trim());
      formData.append("description", inputValue?.description);
      formData.append("link", inputValue?.link);
      formData.append("logo", getcommercialcustomers?.logo);
      ApiPost(`commercialCustomers/addCommercialCustomers`, formData)
        .then((res: any) => {
          if (res?.status == 200) {
            console.log("addres", res);
            setIsAdd(false);
            // toast.success(res?.data?.message);
            setInputValue({});
            setGetcommercialcustomers({});
            getAllcommercialcustomer();
            setLoading(false);
            setIsinfo(false);
          } else {
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };
  const handleUpdate = (e: any) => {
    e.preventDefault();
    if (validateFormUpdate()) {
      setLoading(true);
      let formData = new FormData();
      formData.append("name", inputValue?.name);
      formData.append("description", inputValue?.description);
      formData.append("link", inputValue?.link);
      formData.append("logo", getcommercialcustomers?.logo);
      ApiPut(
        `commercialCustomers/updateCommercialCustomers/${idForUpdate}`,
        formData
      )
        .then((res: any) => {
          if (res?.status == 200) {
            setInputValue({});
            setGetcommercialcustomers({});
            setIsAdd(false);
            setIsEditApi(false);
            toast.success(res?.data?.message);
            getAllcommercialcustomer();
            setLoading(false);
          } else {
            toast.error(res?.data?.message);
            setLoading(false);
          }
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
          setLoading(false);
        });
    }
  };

  const deleteCustmorData = async () => {
    setLoading(true);
    const deleteData: any = {
      isActive: false,
    };
    await ApiPut(
      `commercialCustomers/updateCommercialCustomers/${idForDelete}`,
      deleteData
    )
      .then((res: any) => {
        if (res?.status == 200) {
          setIsDeleteApi(false);
          toast.success(res?.data?.message);
          getAllcommercialcustomer();
          setLoading(false);
        } else {
          toast.error(res?.data?.message);
          setLoading(false);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        setLoading(false);
      });
  };

  const columns: any = [
    {
      name: "Nr.",
      cell: (row: any, index: any) => (page - 1) * countPerPage + (index + 1),
      width: "5%",
    },
    {
      name: "Name",
      selector: (row: any) => row?.name,
      sortable: true,
      width: "15%",
    },
    {
      name: "Bezeichnung",
      selector: (row: any) => row?.description,
      sortable: true,
      width: "20%",
    },
    {
      name: "Link",
      selector: (row: any) => row?.link,
      width: "20%",
    },
    {
      name: "Logo",
      cell: (row: any) => {
        return (
          <>
            <div className="mt-4 mb-4 p-1 border border-dark rounded">
              <img src={row?.logo} width={100} height={50} />
            </div>
          </>
        );
      },
      sortable: true,
      width: "20%",
    },
    {
      name: "Aktionen",
      cell: (row: any) => {
        return (
          <>
            <div className="d-flex justify-content-between">
              <div
                className="cursor-pointer pl-2"
                onClick={() => {
                  setIsAdd(true);
                  setIsEditApi(true);
                  setInputValue({
                    name: row?.name,
                    description: row?.description,
                    link: row?.link
                  });
                  setGetcommercialcustomers({
                    logo: row?.logo,
                  });
                  setIdForUpdate(row?._id);
                }}
              >
                <Tooltip title="Bearbeiten" arrow>
                  <CreateIcon />
                </Tooltip>
              </div>
            </div>
            <div
              className="cursor-pointer"
              onClick={() => {
                setShow(true);
                // setIdForDelete(row?._id);
              }}
            ></div>

            <div
              className="cursor-pointer pl-2"
              onClick={() => {
                setIsDeleteApi(true);
                setIdForDelete(row?._id);
              }}
            >
              <Tooltip title="Löschen" arrow>
                <DeleteIcon />
              </Tooltip>
            </div>
          </>
        );
      },
    },
  ];

  const SearchfilterData =
    Getcustomers &&
    Getcustomers?.filter((el: any, index: any) => {
      if (serachbox === "") {
        return el;
      } else if (serachbox !== "") {
        let fullName = `${el.name}`;
        let res = fullName.toLowerCase();
        return res.match(serachbox.toLowerCase());
      }
    });

  const handleSearch = (e: any) => {
    var value = e.target.value;
    setSerachbox(value);
  };

  // * Table Style
  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: defaultThemes.default.divider.default,
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };

  return (
    <>
      <ToastContainer />
      <div className="card p-1">
        {document.title === "Sayahi | commercial customers" && (
          <ToastContainer />
        )}
        <div className="p-2 mb-2">
          <div className="row mb-4 pr-3">
            <div className="col d-flex justify-content-between">
              <h2 className="pl-3 pt-2">Referenzen</h2>
            </div>
            <div className="col">
              <div>
                <input
                  type="text"
                  className={`form-control form-control-solid `}
                  name="search"
                  // value={search}
                  placeholder="Referenzen suchen…"
                  onChange={(e) => handleSearch(e)}
                />
              </div>
            </div>
            <div className="cus-medium-button-style button-height">
              <button
                onClick={() => {
                  setIsAdd(true);
                }}
                className="btn btn-success mr-2"
              >
                Hinzufügen
              </button>
            </div>
          </div>

          {/*close*/}
          <Modal show={isDeleteApi} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title className="text-danger">Alert!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Sind Sie sicher, dass Sie diesen Kunden löschen möchten?
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Abbrechen
              </Button>
              <Button variant="danger" onClick={() => deleteCustmorData()}>
                Löschen
              </Button>
            </Modal.Footer>
          </Modal>

          <DataTable
            columns={columns}
            data={SearchfilterData}
            customStyles={customStyles}
            style={{
              marginTop: "-3rem",
            }}
            progressPending={isLoaderVisible}
            progressComponent={
              <ThreeCircles color="#334D52" height={30} width={30} />
            }
            highlightOnHover
            // pagination
            // paginationServer
            // paginationTotalRows={count}
            // paginationPerPage={countPerPage}
            // paginationRowsPerPageOptions={[10, 20, 25, 50, 100]}
            // paginationDefaultPage={page}
            // onChangePage={(page) => {
            //   setPage(page);
            // }}
            // onChangeRowsPerPage={(rowPerPage) => {
            //   setCountPerPage(rowPerPage);
            // }}
            pagination
            // paginationServer
            // paginationTotalRows={count}
            paginationPerPage={countPerPage}
            paginationRowsPerPageOptions={[10, 20, 25, 50, 100]}
            paginationDefaultPage={page}
            onChangePage={(page) => {
              setPage(page);
            }}
            onChangeRowsPerPage={(rowPerPage) => {
              setCountPerPage(rowPerPage);
            }}
          />
        </div>
      </div>
      {isAdd ? (
        <Dialog
          fullScreen
          open={isAdd}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
          <List>
            {isAdd === true && (
              <div className="form ml-30 ">
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">
                    Name :
                  </label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <input
                        type="text"
                        className={`form-control form-control-lg form-control-solid `}
                        id="name"
                        name="name"
                        value={inputValue.name}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["name"]}
                    </span>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">
                    Bezeichnung :
                  </label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <textarea
                        // type="text"
                        className={`form-control form-control-lg form-control-solid `}
                        id="description"
                        name="description"
                        value={inputValue.description}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["description"]}
                    </span>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">
                    Link :
                  </label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <input
                        type="text"
                        className={`form-control form-control-lg form-control-solid `}
                        id="link"
                        name="link"
                        value={inputValue.link}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["link"]}
                    </span>
                  </div>
                </div>
                {/* {isEditApi && !inputValue?.imageForUpdate && (
                  <div className="form-group row">
                    <label className="col-xl-3 col-lg-3 col-form-label">logo</label>
                    <div className="col-lg-9 col-xl-6">
                      <div>
                      <input
                        type="file"
                        className={`form-control form-control-lg form-control-solid `}
                        id="logo"
                        name="logo"
                        value={inputValue.logo}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                        <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["logo"]}
                    </span>
                      </div>
                    </div>
                  </div>
                )} */}
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">
                    Logo :
                  </label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <input
                        type="file"
                        className={`form-control form-control-lg form-control-solid `}
                        name="logo"
                        onChange={(e) => {
                          handleOnChangeImg(e);
                        }}
                        accept="icon/*"
                        required
                      />
                    </div>

                    <div>
                      {getcommercialcustomers?.logo && (
                        <img
                          style={{
                            height: "128px",
                            width: "128px",
                            margin: "15px 0",
                            objectFit: "contain",
                          }}
                          src={
                            getcommercialcustomers?.logo
                              ?.toString()
                              ?.includes(".com") === true
                              ? getcommercialcustomers?.logo
                              : URL.createObjectURL(
                                  getcommercialcustomers?.logo
                                )
                          }
                          alt=""
                        />
                      )}
                    </div>

                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["logo"]}
                    </span>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-center">
                  {loading ? (
                    <button className="btn btn-success mr-2">
                      <span>{isEditApi ? "Speichern" : "Speichern"}</span>
                      {loading && (
                        <span className="mx-3 spinner spinner-white"></span>
                      )}
                    </button>
                  ) : (
                    <button
                      onClick={(e) => {
                        isEditApi ? handleUpdate(e) : handleAdd(e);
                      }}
                      className="btn btn-success mr-2"
                    >
                      <span>{isEditApi ? "Speichern" : "Speichern"}</span>
                      {loading && (
                        <span className="mx-3 spinner spinner-white"></span>
                      )}
                    </button>
                  )}
                </div>
              </div>
            )}
          </List>
        </Dialog>
      ) : null}

      {isinfo ? (
        <Dialog
          fullScreen
          open={isinfo}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
          <List>
            {isinfo === true && (
              <div style={{ justifyContent: "center" }}>
                <h5 className="card-title">{idForinfo?.name}</h5>
                <p className="card-text">{idForinfo?.description}</p>
                <p className="card-text">{idForinfo?.link}</p>

                {/* <img {idForinfo?.logo} */}
              </div>
            )}
          </List>
        </Dialog>
      ) : null}
    </>
  );
};

export default Commercialcustomers;
